import React from "react";

import axios from "axios";
import i18n from "i18next";

import k from "i18n/keys";

import {
    keepPreviousData,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import { ITreeListOption } from "common/ITreeListOption";
import { IValueLabelItem } from "common/IValueLabelItem";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";
import { IImpactedProcessValue } from "components/improvements/api/IImpactedProcessValue";
import { ProcessChartImprovementType } from "components/process-chart/api/improvements/ProcessChartImprovementType";

const baseUrl = "/api/processchart";

async function getSelectedProcess(signal?: AbortSignal) {
    const url = `${baseUrl}/selected-process`;

    const response = await axios.get<IValueLabelItem<string, string>>(url, {
        signal,
    });

    return response.data;
}

export const useSelectedProcess = (enabled: boolean = true) => {
    return useQuery({
        queryKey: ["manage-process-chart--selected-process"],

        queryFn: ({ signal }) => getSelectedProcess(signal),

        enabled,
    });
};

async function saveProcess(id?: string) {
    const url = `${baseUrl}/selected-process`;

    const data = new FormData();
    data.append("id", id ?? "");

    await axios.post(url, data);
}

export const useMutateProcess = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: saveProcess,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["manage-process-chart--selected-process"],
            });
        },
    });
};

export const useProcessChartItemNames = (
    request:
        | Partial<
              Record<
                  | ProcessChartImprovementType.Process
                  | ProcessChartImprovementType.SubProcess
                  | ProcessChartImprovementType.ProcessStep,
                  string[]
              >
          >
        | undefined,
) => {
    return useQuery({
        queryKey: ["process-chart-item-names", request],

        queryFn: async ({ signal }) => {
            const url = `${baseUrl}/item-names`;

            const response = await axios.post<
                Record<string, IValueLabelItemWithState<string> | undefined>
            >(url, request, {
                signal,
            });

            return response.data;
        },

        enabled: Boolean(request),
        placeholderData: keepPreviousData,
    });
};

function updateTreeOptionWithDefaultValue(
    processes: ITreeListOption[],
    otherOption: ITreeListOption,
    values: IImpactedProcessValue,
) {
    let el: ITreeListOption | undefined;
    const {
        isNotSure,
        processId,
        subProcessId,
        processStepId,
        isDeleted,
        title,
    } = values;

    if (isNotSure) {
        el = otherOption;
    } else if (processStepId) {
        const processSteps = processes
            .map((x) => x.children)
            .flat()
            .map((x) => x.children)
            .flat();

        el = processSteps.find((x) => x.value === processStepId);
    } else if (subProcessId) {
        const subProcesses = processes.map((x) => x.children).flat();

        el = subProcesses.find((x) => x.value === subProcessId);
    } else if (processId) {
        el = processes.find((x) => x.value === processId);
    }

    if (el) {
        el.isDefaultValue = true;
    } else if (isDeleted) {
        const newOption = {
            ...otherOption,
            className: "hidden-tree-option",
            tagClassName: "deleted-tag",
        };

        newOption.value = processStepId ?? subProcessId ?? processId ?? "";
        newOption.label = title ?? "<deleted>";
        newOption.isDefaultValue = true;
        newOption.isDeleted = isDeleted;

        return processes.concat(otherOption).concat(newOption);
    }

    return processes.concat(otherOption);
}

export const useProcessOptions = (enabled?: boolean) => {
    return useQuery({
        queryKey: ["process-chart--process-options"],

        queryFn: async ({ signal }) => {
            const url = `${baseUrl}/process-options`;

            const response = await axios.get<IValueLabelItem<string, string>[]>(
                url,
                { signal },
            );

            return response.data;
        },

        enabled: enabled ?? true,
    });
};

export const useAllProcessChartOptions = (
    values?: IImpactedProcessValue,
    enabled = true,
) => {
    return useQuery({
        queryKey: ["all-process-chart-options"],

        queryFn: async ({ signal }) => {
            const url = `${baseUrl}/all-options-tree`;

            const response = await axios.get<ITreeListOption[]>(url, {
                signal,
            });

            return response.data;
        },

        enabled,
        select: React.useCallback(
            (data: ITreeListOption[]) => {
                const otherOption: ITreeListOption = {
                    value: "",
                    label: i18n.t(k.OTHER_NOT_SURE),
                    checked: false,
                    children: [],
                    isArchived: false,
                    isDeleted: false,
                };

                if (values) {
                    const newObjectsList = data.map((process) => {
                        return {
                            ...process,

                            children: process.children.map((subProcess) => {
                                return {
                                    ...subProcess,
                                    children: subProcess.children.map(
                                        (processStep) => ({
                                            ...processStep,
                                        }),
                                    ),
                                };
                            }),
                        };
                    });

                    return updateTreeOptionWithDefaultValue(
                        newObjectsList,
                        otherOption,
                        values,
                    );
                }

                return data.concat(otherOption);
            },

            [
                values?.isNotSure,
                values?.processId,
                values?.subProcessId,
                values?.processStepId,
            ],
        ),
    });
};
