import "./EditableStepHeaderTitle.scss";

import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import k from "i18n/keys";

import PrimaryBadge from "common/components/badges/base-badges/badge-primary/PrimaryBadge";
import DeletedBadge from "common/components/badges/simple-badges/badge-deleted/DeletedBadge";
import IconButton from "common/components/buttons/icon-button/IconButton";
import { IMenuOption } from "common/components/buttons/menu-button/IMenuOption";
import MenuButton from "common/components/buttons/menu-button/MenuButton";
import {
    ArrowDownIcon,
    ArrowUpIcon,
    KebabIcon,
    WarningIcon,
} from "common/components/icons";
import { concatToArray } from "common/utils/arrayUtils";
import IValidationMessages from "common/viewModels/IValidationMessages";
import { _improvementStepTitles } from "components/improvement-forms/components/ImprovementForm/utils";
import { getLocaleString } from "i18n/components/GetLocaleString";
import IStep from "models/IStep";
import { showConfirmNoThunk } from "store/confirms/actions";

interface IProps {
    step: IStep;
    index: number;
    lastIndex: number;
    isArchived?: boolean;
    errors?: IValidationMessages;
    improvementStep?: boolean;

    onDeleteStep?: (stepId: string) => void;
    onCopyStep?: (stepId: string) => void;
    onMoveStep?: (newIndex: number, oldIndex: number, stepId: string) => void;
}

const EditableStepHeaderTitle = (props: IProps) => {
    const {
        step,
        index,
        lastIndex,
        isArchived,
        errors,
        improvementStep,
        onDeleteStep,
        onCopyStep,
        onMoveStep,
    } = props;

    const {
        t,
        i18n: { language: localeId },
    } = useTranslation();
    const dispatch = useDispatch();

    const isDisabled = step.isDeleted || isArchived || step.isLocal !== true;

    const error = concatToArray(
        errors?.["step." + step.id + ".activities"] ?? [],
        errors?.["step." + step.id + ".name"] ?? [],
    );

    const stepName =
        improvementStep && step.name
            ? index +
              1 +
              ". " +
              getLocaleString(t, _improvementStepTitles(step.name, true, false))
            : t(k.STEP) + " " + (index + 1);

    const handleOnCopyStep = async () => {
        if (await showConfirmNoThunk(dispatch, t(k.COPY_STEP_MESSAGE))) {
            onCopyStep?.(step.id);
        }
    };

    const handleOnOptionsClick = async (option: string) => {
        if (option === "copy") {
            await handleOnCopyStep();
        }
        if (option === "delete") {
            onDeleteStep?.(step.id);
        }
    };

    const handlMoveStepUp = () => {
        onMoveStep?.(index - 1, index, step.id);
    };

    const handlMoveStepDown = () => {
        onMoveStep?.(index + 1, index, step.id);
    };

    const canCopyStep = step.isLocal !== false && onCopyStep !== undefined;

    const menuOptions: IMenuOption[] = useMemo(() => {
        const options: IMenuOption[] = [];

        if (!improvementStep) {
            options.push({
                id: "delete",
                label: t(step.isDeleted ? k.RESTORE : k.DELETE),
                testId: "menu-button-delete",
            });

            if (canCopyStep) {
                options.push({
                    id: "copy",
                    label: t(k.COPY_STEP),
                    disabled: isDisabled,
                    testId: "menu-button-copy",
                });
            }
        }

        return options;
    }, [localeId, canCopyStep, isDisabled, improvementStep]);

    return (
        <>
            <div
                className="editable-step__header__title__left"
                data-testid="step-title"
            >
                {stepName}

                {step.isLocal !== true && (
                    <PrimaryBadge testId="shared-step-badge">
                        {t(k.SHARED_STEP)}
                    </PrimaryBadge>
                )}
                {step.isDeleted && <DeletedBadge testId="deleted-step-badge" />}

                {!!error.length && (
                    <WarningIcon
                        active
                        tooltip={{
                            id: "step." + step.id + ".header.title",
                            message: getLocaleString(t, error),
                            placement: "bottom",
                        }}
                    />
                )}
            </div>
            {!improvementStep && (
                <div className="editable-step__header__title__right">
                    {index > 0 ? (
                        <IconButton
                            tooltip={t(k.MOVE_STEP_UP)}
                            onClick={handlMoveStepUp}
                        >
                            <ArrowUpIcon />
                        </IconButton>
                    ) : (
                        <div className="empty-icon-button" />
                    )}
                    {index < lastIndex ? (
                        <IconButton
                            tooltip={t(k.MOVE_STEP_DOWN)}
                            onClick={handlMoveStepDown}
                        >
                            <ArrowDownIcon />
                        </IconButton>
                    ) : (
                        <div className="empty-icon-button" />
                    )}
                    {!!menuOptions.length && (
                        <MenuButton
                            testId="step-menu"
                            icon={<KebabIcon variant="dark-blue" />}
                            className="editable-step__header__menu"
                            options={menuOptions}
                            onSelect={handleOnOptionsClick}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default EditableStepHeaderTitle;
