import "./StepInstanceCard.scss";

import React, { useRef } from "react";

import { useTranslation } from "react-i18next";

import NumberOfMax from "../number-of-max/NumberOfMax";
import BlockedMessage from "./BlockedMessage";
import CommentButton from "common/components/buttons/comment-button/CommentButton";
import { _improvementStepTitles } from "components/improvement-forms/components/ImprovementForm/utils";
import { getLocaleString } from "i18n/components/GetLocaleString";
import IStepInstance from "models/IStepInstance";

interface IProps {
    stepInstance: IStepInstance;

    orderIndex?: number;

    activityInstanceList?: React.ReactNode;
    finalizeCard?: React.ReactNode;
    commentList?: React.ReactNode;
    teamList?: React.ReactNode;
    equipmentList?: React.ReactNode;
    stepRef?: React.RefObject<HTMLDivElement>;
    isImprovement?: boolean;

    disableComments?: boolean;
}

const StepInstanceCard: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const {
        stepInstance,
        orderIndex,
        activityInstanceList,
        finalizeCard,
        commentList,
        teamList,
        equipmentList,
        disableComments,
        isImprovement,
    } = props;

    const {
        name,
        completedActivitiesCount = 0,
        totalActivitiesCount = 0,
    } = stepInstance;
    const commentRef = useRef<HTMLDivElement>(null);

    const handleCommentClick = () => {
        if (commentRef.current) {
            commentRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    };
    const { t } = useTranslation();

    const description = isImprovement ? undefined : stepInstance.description;

    const stepInstanceName = isImprovement
        ? _improvementStepTitles(name, false, false)
        : name;
    return (
        <div
            className="step-instance--wrapper"
            ref={props.stepRef}
            data-id={stepInstance.id}
            data-testid={stepInstance.id}
        >
            <div className="step-instance--header">
                <div className="step-instance--header-title">
                    <h3>
                        {orderIndex}. {getLocaleString(t, stepInstanceName)}
                    </h3>
                    {disableComments ? null : (
                        <div className="flex-container">
                            <div className="column-item">
                                <NumberOfMax
                                    value={completedActivitiesCount}
                                    max={totalActivitiesCount}
                                    variant={
                                        completedActivitiesCount === 0
                                            ? undefined
                                            : completedActivitiesCount <
                                                totalActivitiesCount
                                              ? "draft"
                                              : "success"
                                    }
                                />
                            </div>

                            <div className="column-item">
                                <CommentButton
                                    count={stepInstance.commentCount}
                                    onClick={handleCommentClick}
                                />
                            </div>
                        </div>
                    )}
                </div>

                {teamList && (
                    <div className="step-instance--header--teams">
                        {teamList}
                    </div>
                )}
                {equipmentList && (
                    <div className="step-instance--header--equipments">
                        {equipmentList}
                    </div>
                )}
                {description && (
                    <div className="step-instance--header--description">
                        {description}
                    </div>
                )}
                {(stepInstance.isBlocked || stepInstance.isBlocking) && (
                    <div className="step-instance--header--block">
                        <BlockedMessage stepInstance={stepInstance} />
                    </div>
                )}
            </div>
            <div className="step-instance--body">{activityInstanceList}</div>
            <div className="step-instance--footer">
                {commentList && (
                    <div
                        ref={commentRef}
                        className="step-instance--footer-comments no-print"
                    >
                        {commentList}
                    </div>
                )}
                <div className="step-instance--footer-finalize">
                    {finalizeCard}
                </div>
            </div>
        </div>
    );
};

export default StepInstanceCard;
