import "./EditableStep.scss";

import React from "react";

import EditableStepHeaderDetails from "./components/EditableStepHeaderDetails/EditableStepHeaderDetails";
import EditableStepHeaderTitle from "./components/EditableStepHeaderTitle/EditableStepHeaderTitle";
import { EParentType } from "common/components/listed-content/components/SidePanel/List/components/ListItems/ListItems";
import StepCardWrapper from "common/components/step-card/StepCardWrapper";
import IValidationMessages from "common/viewModels/IValidationMessages";
import ImprovementFormEditableActivityList from "components/improvement-forms/components/ImprovementForm/components/Content/components/EditableActivityList";
import { _improvementStepTitles } from "components/improvement-forms/components/ImprovementForm/utils";
import ChecklistTemplateEditableActivityList from "components/templates/components/TemplateListedContent/Form/components/Content/components/EditableActivityList";
import ITableSet from "http/ITableSet";
import IStep from "models/IStep";

interface IProps {
    step: IStep;
    stepRef: React.RefObject<HTMLDivElement>;
    index: number;
    lastIndex: number;
    parentType: EParentType;

    isArchived?: boolean;
    errors?: IValidationMessages;

    headerDetailsContent?: React.ReactNode;
    footerContent?: React.ReactNode;

    onDeleteStep?: (stepId: string) => void;
    onCopyStep?: (stepId: string) => void;
    onMoveStep?: (newIndex: number, oldIndex: number, stepId: string) => void;
    setSteps?: React.Dispatch<React.SetStateAction<ITableSet<IStep>>>;
    setErrors?: React.Dispatch<
        React.SetStateAction<IValidationMessages | undefined>
    >;

    onHaveChanges: (value: boolean) => void;
}

const EditableStep = (props: React.PropsWithChildren<IProps>) => {
    const {
        step,
        index,
        lastIndex,
        isArchived,
        headerDetailsContent,
        footerContent,
        errors,
        parentType,
        onHaveChanges,
        setSteps,
        setErrors,
        onDeleteStep,
        onCopyStep,
        onMoveStep,
    } = props;

    const isDisabled = step.isDeleted || isArchived || step.isLocal !== true;

    const error =
        errors?.["step." + step.id + ".activities"] ||
        errors?.["step." + step.id + ".name"];

    const useDefaultHeaderDetailsContent =
        parentType !== EParentType.improvement;

    return (
        <StepCardWrapper
            id={`step.${step.id}.name`}
            dataId={step.id}
            stepRef={props.stepRef}
            testId={`editable-step-${index + 1}`}
            invalid={!!error}
            titleContent={
                <EditableStepHeaderTitle
                    step={step}
                    index={index}
                    lastIndex={lastIndex}
                    isArchived={isArchived}
                    errors={errors}
                    improvementStep={parentType === EParentType.improvement}
                    onDeleteStep={onDeleteStep}
                    onCopyStep={onCopyStep}
                    onMoveStep={onMoveStep}
                />
            }
            headerDetailsContent={
                useDefaultHeaderDetailsContent ? (
                    <EditableStepHeaderDetails
                        step={step}
                        isArchived={isArchived}
                        errors={errors}
                        onHaveChanges={onHaveChanges}
                        setSteps={setSteps}
                        setErrors={setErrors}
                    />
                ) : (
                    headerDetailsContent
                )
            }
            bodyContent={
                !step.isDeleted &&
                (parentType === EParentType.improvement ? (
                    <ImprovementFormEditableActivityList
                        stepIndex={index + 1}
                        stepId={step.id}
                        stepDisabled={isDisabled}
                    />
                ) : (
                    <ChecklistTemplateEditableActivityList
                        stepIndex={index + 1}
                        stepId={step.id}
                        stepDisabled={isDisabled}
                    />
                ))
            }
            footerContent={footerContent}
        />
    );
};

export default EditableStep;
