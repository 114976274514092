import "./ImpactedProcessesCard.scss";

import React, { useMemo } from "react";

import { Trans, useTranslation } from "react-i18next";
import { SingleValueProps, components } from "react-select";

import { t } from "i18next";

import k from "i18n/keys";

import { IImpactedProcessValue } from "../api/IImpactedProcessValue";
import { ITreeListOption } from "common/ITreeListOption";
import DropdownTreeSelect from "common/components/dropdown-tree-select/DropdownTreeSelect";
import { ITreeListOption as IFlatTreeListOption } from "common/components/dropdown-tree-select/DropdownTreeSelect";
import { ArrowRightIcon, InfoIcon } from "common/components/icons";
import InputWrapper from "common/components/input-components/InputWrapper";
import { ESelectedProcessType } from "common/components/input-components/dropdown-tree/ESelectedProcessType";
import ValueWrapper from "common/components/value-wrapper/ValueWrapper";
import IValidationMessages from "common/viewModels/IValidationMessages";
import { ITableSetWithOptions } from "http/ITableSetWithOptions";

interface IProps {
    errors?: IValidationMessages;
    discoveredOptions?: ITreeListOption[];
    originatedOptions?: ITreeListOption[];
    disabled?: boolean;
    onChange?: (
        name: "originated" | "discovered",
        data: IImpactedProcessValue,
    ) => void;
}

function convertTreeToTableSet<
    V extends string = string,
    L = string,
    P = boolean,
>(
    nodes: ITreeListOption<V, L, P>[],
): ITableSetWithOptions<IFlatTreeListOption<V, L, boolean>, V, V> {
    const values: Record<V, IFlatTreeListOption<V, L, boolean>> = {} as Record<
        V,
        IFlatTreeListOption<V, L, boolean>
    >;
    const ids: V[] = [];
    const options: V[] = [];

    function traverse(
        node: ITreeListOption<V, L, P>,
        parentId: V | null = null,
    ) {
        const nodeId = node.value;
        ids.push(nodeId);

        const { children, ...rest } = node;
        const childIds = children ? children.map((child) => child.value) : [];

        const flatNode: IFlatTreeListOption<V, L, boolean> = {
            ...rest,
            param: rest.isDeleted,
            __checked: rest.checked || rest.isDefaultValue,
            parentId: parentId,
            childIds: childIds,
        };

        values[nodeId] = flatNode;

        if (children && children.length > 0) {
            children.forEach((child) => traverse(child, nodeId));
        }
    }

    nodes.forEach((node) => {
        options.push(node.value);
        traverse(node, null);
    });

    return {
        ids: ids,
        values: values,
        options: options,
    };
}

const getStyleByIndex = (index: number) => {
    switch (index) {
        case 0:
            return {
                color: "#040930",
                backgroundColor: "#fff",
                borderColor: "#DFE8FC",
            };
        case 1:
            return { color: "#fff", backgroundColor: "#647E9E" };
        default:
            return { color: "#fff", backgroundColor: "#152B6F" };
    }
};

const createSingleValueComponent = (
    data: ITableSetWithOptions<IFlatTreeListOption, string, string>,
) => {
    const SingleValueComponent: React.FC<
        SingleValueProps<IFlatTreeListOption<string, string, any>, false>
    > = (props) => {
        const { data: optionData } = props;

        const pathOptionIds = [...(optionData?.__path ?? []), optionData.value];

        return (
            <components.SingleValue {...props} key={optionData.value}>
                <div className="single-value-option">
                    {pathOptionIds?.map((optionId, index) => {
                        const option = data.values[optionId];
                        const style = !option?.param
                            ? getStyleByIndex(index)
                            : undefined;
                        return (
                            <React.Fragment key={option?.value}>
                                {index > 0 && <ArrowRightIcon />}
                                <ValueWrapper
                                    customColor={style?.color}
                                    customBackgroundColor={
                                        style?.backgroundColor
                                    }
                                    customBorderColor={style?.borderColor}
                                    isDeleted={option?.param}
                                >
                                    <div className="deleted-option-input">
                                        {option?.label}
                                        {option?.param && (
                                            <InfoIcon
                                                size="small"
                                                tooltip={{
                                                    id: `${option?.value}-deleted-option-input`,
                                                    message: t(
                                                        k.SELECTED_OPTION_WAS_DELETED,
                                                    ),
                                                }}
                                            />
                                        )}
                                    </div>
                                </ValueWrapper>
                            </React.Fragment>
                        );
                    })}
                </div>
            </components.SingleValue>
        );
    };

    return SingleValueComponent;
};

const ImpactedProcessesCard = (props: IProps) => {
    const { errors, discoveredOptions, originatedOptions, disabled } = props;

    const discoveredData = useMemo(() => {
        return convertTreeToTableSet(discoveredOptions ?? []);
    }, [discoveredOptions]);
    const originatedData = useMemo(() => {
        return convertTreeToTableSet(originatedOptions ?? []);
    }, [originatedOptions]);

    const SingleValueComponentDiscovered =
        createSingleValueComponent(discoveredData);
    const SingleValueComponentOriginated =
        createSingleValueComponent(originatedData);

    const { t } = useTranslation();

    const handleChangeDiscovered = (
        selectedValues: IFlatTreeListOption<string, string, any>[],
    ) => {
        handleChange("discovered", selectedValues[0]);
    };

    const handleChangeOriginated = (
        selectedValues: IFlatTreeListOption<string, string, any>[],
    ) => {
        handleChange("originated", selectedValues[0]);
    };

    const handleChange = (
        name: "originated" | "discovered",
        currentNode?: IFlatTreeListOption | null,
    ) => {
        if (props.onChange) {
            if (currentNode?.value) {
                const nodeType = currentNode.__depth;

                switch (nodeType) {
                    case ESelectedProcessType.process:
                        props.onChange(name, {
                            isNotSure: false,
                            processId: currentNode.value,
                        });
                        break;

                    case ESelectedProcessType.sub_process:
                        props.onChange(name, {
                            isNotSure: false,
                            subProcessId: currentNode.value,
                        });
                        break;

                    case ESelectedProcessType.process_step:
                        props.onChange(name, {
                            isNotSure: false,
                            processStepId: currentNode.value,
                        });
                        break;
                }
            } else {
                props.onChange(name, {
                    isNotSure: true,
                });
            }
        }
    };

    const invalid = Boolean(
        errors?.discovered ||
            errors?.originated ||
            errors?.["impactedProcesses.discovered"] ||
            errors?.["impactedProcesses.originated"],
    );

    return (
        <>
            <div
                className={
                    invalid
                        ? "activity-instance--card impacted-processes-card impacted-processes-card__invalid"
                        : "activity-instance--card impacted-processes-card"
                }
            >
                <h6 className="activity-instance--title">
                    <strong>{t(k.CHOOSE_IMPACTED_PROCESSES)}</strong>
                </h6>

                <InputWrapper
                    id={"impactedProcesses.discovered"}
                    htmlFor="deviationDiscovered"
                    wrapperLabel={
                        <React.Fragment>
                            <Trans i18nKey={k.DISCOVERED_IN_T} />{" "}
                            <span className="text--danger">*</span>
                        </React.Fragment>
                    }
                >
                    <DropdownTreeSelect
                        data={discoveredData}
                        inputDataTestId="deviation-discovered-in-process-dropdown-input"
                        onChange={handleChangeDiscovered}
                        disabled={disabled}
                        customOptionLabel={(option) =>
                            option.param ? (
                                <div className="deleted-option">
                                    {option.label}{" "}
                                    <InfoIcon
                                        size="small"
                                        tooltip={{
                                            id: `${option.value}-deleted-option`,
                                            message: t(
                                                k.SELECTED_OPTION_WAS_DELETED,
                                            ),
                                        }}
                                    />
                                </div>
                            ) : (
                                <>{option.label}</>
                            )
                        }
                        selectedOptionValues={Object.values(
                            discoveredData.values,
                        )
                            .filter((v) => v?.__checked)
                            .map((v) => v!.value)}
                        showPartiallySelected
                        components={{
                            SingleValue: SingleValueComponentDiscovered,
                        }}
                    />
                </InputWrapper>

                <InputWrapper
                    id={"impactedProcesses.originated"}
                    htmlFor="deviationOriginated"
                    wrapperLabel={
                        <React.Fragment>
                            <Trans i18nKey={k.ORIGINATED_IN_T} />{" "}
                            <span className="text--danger">*</span>
                        </React.Fragment>
                    }
                >
                    <DropdownTreeSelect
                        data={originatedData}
                        onChange={handleChangeOriginated}
                        inputDataTestId="deviation-originated-in-process-dropdown-input"
                        customOptionLabel={(option) =>
                            option.param ? (
                                <div className="deleted-option">
                                    {option.label}
                                    <InfoIcon
                                        size="small"
                                        tooltip={{
                                            id: `${option.value}-deleted-option`,
                                            message: t(
                                                k.SELECTED_OPTION_WAS_DELETED,
                                            ),
                                        }}
                                    />
                                </div>
                            ) : (
                                <>{option.label}</>
                            )
                        }
                        selectedOptionValues={Object.values(
                            originatedData.values,
                        )
                            .filter((v) => v?.__checked)
                            .map((v) => v!.value)}
                        disabled={disabled}
                        showPartiallySelected
                        components={{
                            SingleValue: SingleValueComponentOriginated,
                        }}
                    />
                </InputWrapper>
            </div>
        </>
    );
};

export default ImpactedProcessesCard;
