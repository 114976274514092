import "./ValidationWrapper.scss";

import classNames from "classnames";

import ValidationLabel from "./ValidationLabel";

interface IProps {
    errors?: React.ReactNode;
    testId?: string;
    absolute?: boolean;
}

const ValidationWrapper: React.FC<React.PropsWithChildren<IProps>> = ({
    errors,
    testId,
    children,
    absolute,
}) => {
    const className = classNames("validation-wrapper", {
        absolute,
    });
    return (
        <div className={className}>
            {children}
            <ValidationLabel darkColor errors={errors} testId={testId} />
        </div>
    );
};

export default ValidationWrapper;
