import "./GoalStatusComment.scss";

import { PropsWithRef, RefObject, useState } from "react";

import { useTranslation } from "react-i18next";
import { BiBlock } from "react-icons/bi";
import { FiCheck } from "react-icons/fi";

import k from "i18n/keys";

import { Button } from "../buttons";
import { IInputActionChangeEvent } from "components/common/actions/IInputActionProps";
import ActionTextField from "components/common/actions/inputActions/components/ActionTextField";
import { EGoalGrading } from "components/goals-page/api/EGoalGrading";

interface IProps {
    status?: EGoalGrading;
    comment?: string;
    editMode?: boolean;
    canEdit?: boolean;
    editable?: boolean;
    inputRef?: RefObject<HTMLInputElement | HTMLTextAreaElement>;
    onChangeStatusComment?: (e: IInputActionChangeEvent<string>) => void;
    onSaveStatusComment?: () => void;
}

const GoalStatusComment = (props: PropsWithRef<IProps>) => {
    const {
        status,
        editMode,
        canEdit,
        comment,
        inputRef,
        editable = true,
    } = props;

    const [isFocused, setIsFocused] = useState(false);

    const { t } = useTranslation();

    const hidden =
        status === EGoalGrading.OnTrack ||
        status === EGoalGrading.Completed ||
        status === EGoalGrading.Draft;

    const handleOnFocus = () => {
        setIsFocused(true);
    };

    const handleOnBlur = () => {
        setIsFocused(false);
        if (!editMode) {
            props.onSaveStatusComment?.();
        }
    };

    const isBlocked = status === EGoalGrading.Blocked;

    return (
        <div
            className={
                "goal-status-comment" +
                (isBlocked ? " blocked" : " at-risk") +
                (hidden ? " hidden" : "")
            }
        >
            {isBlocked && (
                <>
                    <div className="goal-status-comment--icon">
                        <BiBlock />
                    </div>
                    <div className="goal-status-comment--title">
                        {t(k.WHY_GOAL_BLOCKED)}:
                    </div>
                </>
            )}
            {!isBlocked && (
                <div className="goal-status-comment--title">
                    {t(k.WHY_GOAL_AT_RISK)}:
                </div>
            )}
            <ActionTextField
                id="goal-status-comment"
                inputRef={inputRef}
                placeholder={t(k.NO_COMMENT_PROVIDED)}
                className={`status-comment-input${
                    !isFocused && !editMode ? " inactive" : " active"
                }${isBlocked ? " blocked" : " at-risk"}`}
                testId="goal-status-comment"
                name="comment"
                fullWidth
                editMode={editMode}
                editable={editable}
                disabled={!canEdit}
                value={comment ?? ""}
                onChange={props.onChangeStatusComment}
                onBlur={handleOnBlur}
                onFocus={handleOnFocus}
            />
            {!editMode && isFocused && (
                <Button
                    transparent
                    size="small"
                    variant="white"
                    className="dummy-btn"
                >
                    <FiCheck />
                </Button>
            )}
        </div>
    );
};

export default GoalStatusComment;
