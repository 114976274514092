import "./CustomListItemTable.scss";

import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";

import k from "i18n/keys";

import { CustomListItemSortBy } from "../api/CustomListItemSortBy";
import ICustomListItemFilter from "../api/ICustomListItemFilter";
import {
    useCachedAddedCustomListItemIds,
    useCustomListItemList,
    useCustomListItemNamesByIds,
} from "../api/hooks";
import { useCheckIfCanRunChecklists } from "../api/hooks/CheckIfCanRunChecklists";
import EmptyImage from "../assets/empty.svg";
import CustomListItemFilterMenu from "./CustomListItemFilterMenu";
import HeaderFilters from "./HeaderFilters";
import Row from "./Row";
import IChanged from "common/IChanged";
import Button from "common/components/buttons/Button";
import Pagination from "common/components/pagination/Pagination";
import PortalOpener from "common/components/portals/PortalOpener";
import {
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
} from "common/components/table";
import {
    sharedOptionTypeByPropertyType,
    showFilterCustomListTypes,
} from "components/custom-list-item-page/utils/utility";
import { CustomListPropertyEnum } from "components/custom-list-page/api/CustomListPropertyEnum";
import {
    ICustomListPropertyTemplateValue,
    IMutualCustomListPropertyDto,
} from "components/custom-list-page/api/ICustomListPropertyTemplateValue";
import {
    useCustomListPermissions,
    usePropertiesByCustomListId,
} from "components/custom-list-page/api/hooks";

const DEFAULT_PAGE_SIZE = 10;

function getInitialFilterState(
    customListId?: string,
    customListItemId?: string,
    mutualProperty?: IMutualCustomListPropertyDto,
): IChanged<ICustomListItemFilter> {
    return {
        value: {
            pageSize: DEFAULT_PAGE_SIZE,

            customListId,
            customListItemId,
            isChildMutualProperty: mutualProperty?.isNotParent,
            propertyParentId: mutualProperty?.parentId,

            sortBy: CustomListItemSortBy.CreatedAt,
            sortAsc: false,
        },
    };
}

interface IProps {
    enableSync?: boolean;
    customListId: string;
    customListItemId?: string;
    customListItemName?: string;
    mutualProperty?: IMutualCustomListPropertyDto;
    showEmpty?: boolean;

    showAboveHeaderFilters?: boolean;

    className?: string;

    filterRefId?: string;

    onAddNew?: () => void;
    onRowSelected?: (itemId: string) => void;
}

const CustomListItemTable = (props: IProps) => {
    const {
        enableSync = true,
        customListId,
        customListItemId,
        mutualProperty,
        showEmpty,
        showAboveHeaderFilters,
        filterRefId,
        customListItemName,
    } = props;

    const excludeMutualPropertyParentId = mutualProperty?.parentId ?? null;

    const { t } = useTranslation();

    const { data: permissions } = useCustomListPermissions(customListId);

    const canEdit = permissions?.canEdit;

    const [currentFilter, setCurrentFilter] = useState<
        IChanged<ICustomListItemFilter>
    >(getInitialFilterState(customListId, customListItemId, mutualProperty));

    React.useEffect(() => {
        setCurrentFilter(
            getInitialFilterState(
                customListId,
                customListItemId,
                mutualProperty,
            ),
        );
    }, [customListId, customListItemId, mutualProperty]);

    const { sortBy, sortAsc, page } = currentFilter.value;

    const {
        data: items,
        isLoading,
        isFetching,
        isFetched,
    } = useCustomListItemList(enableSync, currentFilter.value);

    const { data: addedItemIds } = useCachedAddedCustomListItemIds();

    const { data: canRunChecklistsWithIds } = useCheckIfCanRunChecklists(
        items?.ids ?? [],
    );

    React.useEffect(() => {
        if (
            enableSync &&
            isFetched &&
            isFetching === false &&
            (items?.ids.length ?? 0) === 0
        ) {
            setCurrentFilter((prev) =>
                (prev.value.page ?? 1) > 1
                    ? {
                          ...prev,
                          value: {
                              ...prev.value,
                              page: (prev.value.page ?? 1) - 1,
                          },
                      }
                    : prev,
            );
        }
    }, [enableSync, isFetching, isFetched]);

    const { data: selectedCustomListItemsNames } = useCustomListItemNamesByIds(
        items?.uniqueSelectedCustomListItemIds,
    );

    const { data: selectedProperties } =
        usePropertiesByCustomListId(customListId);

    const handleSort = (
        e: React.UIEvent,
        isBusy?: boolean,
        name?: string | number,
    ) => {
        if (isBusy) {
            return;
        }

        const isNotCustomSortBy = typeof name === "number";

        setCurrentFilter((prev) => ({
            ...prev,

            value: {
                ...prev.value,
                sortBy: name,
                customSortBy: isNotCustomSortBy ? undefined : name,
                sortAsc:
                    prev.value.sortBy === name ? !prev.value.sortAsc : true,
            },
        }));
    };

    const handleFilter = (
        property: ICustomListPropertyTemplateValue,
        ids: string[],
    ) => {
        setCurrentFilter((prev) => {
            const mutualPropertyParentId = property.mutualProperty?.isNotParent
                ? property.mutualProperty.parentId
                : undefined;

            return {
                ...prev,

                isChanged: true,

                value: {
                    ...prev.value,

                    page: 1,

                    customFilters: mutualPropertyParentId
                        ? prev.value.customFilters
                        : {
                              ...prev.value.customFilters,
                              [property.parentId]: ids,
                          },

                    mutualPropertyCustomFilters: mutualPropertyParentId
                        ? {
                              ...prev.value.mutualPropertyCustomFilters,
                              [mutualPropertyParentId]: ids,
                          }
                        : prev.value.mutualPropertyCustomFilters,
                },
            };
        });
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentFilter((prev) => ({
            ...prev,

            value: {
                ...prev.value,
                page: pageNumber,
            },
        }));
    };

    const totalItemsCount =
        (items?.totalCount ?? 0) + (addedItemIds?.length ?? 0);

    const recordsCount = (items?.ids.length ?? 0) + (addedItemIds?.length ?? 0);

    return (
        <Table
            className="custom-list-item--table"
            wrapperClassName={`custom-list-item--table__wrapper${
                props.className ? " " + props.className : ""
            }`}
            resizable
            resizingKey="name"
            name={customListItemName + "-table"}
            isLoading={isLoading}
            recordsCount={recordsCount}
            hasFilters={currentFilter.isChanged}
            columnsCount={
                2 +
                (selectedProperties?.numberOfColumns ?? 0) +
                (canRunChecklistsWithIds?.anyResult ? 1 : 0) +
                (items?.hasActiveChecklists ? 1 : 0) +
                (items?.hasFinalizedChecklists ? 1 : 0)
            }
            emptyMessage={
                showEmpty && (
                    <>
                        <h3>{t(k.ITS_EMPTY_HERE)}</h3>

                        {props.onAddNew && canEdit && (
                            <Button
                                onClick={props.onAddNew}
                                size="small"
                                icon={<FiPlus />}
                            >
                                {t(k.ADD)}
                            </Button>
                        )}
                    </>
                )
            }
            emptyTableImage={<EmptyImage />}
            noResultsMessage={
                <>
                    <h3>{t(k.NO_RESULTS)}</h3>
                    {t(k.TRY_DIFFERENT_FILTER_KEYWORDS)}
                </>
            }
            pagination={
                <Pagination
                    isBusy={isLoading}
                    activePage={page ?? 1}
                    pageSize={DEFAULT_PAGE_SIZE}
                    totalCount={totalItemsCount}
                    onChange={handlePageChange}
                />
            }
            wrapperToolbar={
                selectedProperties?.isSearchable &&
                showAboveHeaderFilters &&
                (filterRefId ? (
                    <PortalOpener targetId={filterRefId}>
                        <HeaderFilters
                            value={currentFilter.value}
                            onChange={setCurrentFilter}
                        />
                    </PortalOpener>
                ) : (
                    <HeaderFilters
                        value={currentFilter.value}
                        onChange={setCurrentFilter}
                    />
                ))
            }
        >
            <TableHeader>
                <TableRow>
                    {selectedProperties?.visiblePropertyIds.map(
                        (propertyId) => {
                            const property =
                                selectedProperties.values[propertyId];

                            if (!property) {
                                return null;
                            }
                            const showFilter =
                                property.isFilterable &&
                                showFilterCustomListTypes.includes(
                                    property.type,
                                );

                            if (
                                excludeMutualPropertyParentId &&
                                property.mutualProperty?.parentId ===
                                    excludeMutualPropertyParentId &&
                                selectedProperties.isAnyNotCustomListProperty
                            ) {
                                return null;
                            }

                            const minWidth =
                                property.type ===
                                    CustomListPropertyEnum.Barcodes ||
                                property.type ===
                                    CustomListPropertyEnum.GenerateBarcode
                                    ? "md"
                                    : undefined;

                            const width =
                                property.type ===
                                    CustomListPropertyEnum.Barcodes ||
                                property.type ===
                                    CustomListPropertyEnum.GenerateBarcode
                                    ? "md"
                                    : "sm";

                            return (
                                <TableCell
                                    key={propertyId}
                                    name={property.parentId}
                                    minWidth={minWidth}
                                    width={width}
                                    onClick={
                                        property.isSortable
                                            ? handleSort
                                            : undefined
                                    }
                                    sortingBy={
                                        property.isSortable ? sortBy : undefined
                                    }
                                    sortingIsAsc={sortAsc}
                                    filterMenu={
                                        showFilter &&
                                        property && (
                                            <CustomListItemFilterMenu
                                                property={property}
                                                sharedOptionType={
                                                    sharedOptionTypeByPropertyType[
                                                        property.type
                                                    ]
                                                }
                                                onFilter={handleFilter}
                                            />
                                        )
                                    }
                                >
                                    {
                                        selectedProperties.values[propertyId]
                                            ?.label
                                    }
                                </TableCell>
                            );
                        },
                    )}

                    <TableCell
                        name={CustomListItemSortBy.CreatedAt}
                        onClick={handleSort}
                        sortingBy={sortBy}
                        sortingIsAsc={sortAsc}
                        width="sm"
                    >
                        {t(k.CREATED)}
                    </TableCell>

                    <TableCell
                        name={CustomListItemSortBy.UpdatedAt}
                        onClick={handleSort}
                        sortingBy={sortBy}
                        sortingIsAsc={sortAsc}
                        width="sm"
                    >
                        {t(k.UPDATED)}
                    </TableCell>

                    {canRunChecklistsWithIds?.anyResult && (
                        <TableCell>{t(k.NAV_BUTTON_RUN_CHECKLIST)}</TableCell>
                    )}

                    {items?.hasActiveChecklists && (
                        <TableCell>{t(k.ACTIVE_CHECKLISTS)}</TableCell>
                    )}

                    {items?.hasFinalizedChecklists && (
                        <TableCell>{t(k.FINALIZED_CHECKLISTS)}</TableCell>
                    )}
                </TableRow>
            </TableHeader>

            <TableBody>
                {addedItemIds?.map((id) => (
                    <Row
                        key={id}
                        id={id}
                        defaultSelectedProperties={selectedProperties ?? null}
                        selectedCustomListItemsNames={
                            selectedCustomListItemsNames ?? null
                        }
                        tasklistOptionsById={
                            items?.values[id]?.tasklistOptionsByIds ?? null
                        }
                        isNewItem
                        canRunChecklistsWithIdsHasAnyValues={
                            canRunChecklistsWithIds?.anyResult ?? false
                        }
                        canRunChecklistsWithIds={
                            canRunChecklistsWithIds?.values
                        }
                        excludeMutualPropertyParentId={
                            excludeMutualPropertyParentId
                        }
                        hasActiveChecklists={
                            items?.hasActiveChecklists ?? false
                        }
                        activeChecklists={items?.activeChecklists}
                        hasFinalizedChecklists={
                            items?.hasFinalizedChecklists ?? false
                        }
                        finalizedChecklists={items?.finalizedChecklists}
                        onSelectRow={props.onRowSelected}
                        onPreviewCustomListItem={props.onRowSelected}
                    />
                ))}

                {items?.ids.map((id) => (
                    <Row
                        key={id}
                        id={id}
                        defaultSelectedProperties={selectedProperties ?? null}
                        selectedCustomListItemsNames={
                            selectedCustomListItemsNames ?? null
                        }
                        tasklistOptionsById={
                            items.values[id]?.tasklistOptionsByIds ?? null
                        }
                        canRunChecklistsWithIdsHasAnyValues={
                            canRunChecklistsWithIds?.anyResult ?? false
                        }
                        canRunChecklistsWithIds={
                            canRunChecklistsWithIds?.values
                        }
                        excludeMutualPropertyParentId={
                            excludeMutualPropertyParentId
                        }
                        hasActiveChecklists={
                            items?.hasActiveChecklists ?? false
                        }
                        activeChecklists={items?.activeChecklists}
                        hasFinalizedChecklists={
                            items?.hasFinalizedChecklists ?? false
                        }
                        finalizedChecklists={items?.finalizedChecklists}
                        onSelectRow={props.onRowSelected}
                        onPreviewCustomListItem={props.onRowSelected}
                    />
                ))}
            </TableBody>
        </Table>
    );
};

export default CustomListItemTable;
