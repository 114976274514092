import "./Button.scss";

import React, { useEffect, useMemo, useState } from "react";

import Spinner from "react-bootstrap/Spinner";

import { LinkWrapper } from "components/common/link-wrapper";

const TIMEOUT_MS = 400;

export type ButtonVariantTypes =
    | "gray"
    | "blue"
    | "dark-blue"
    | "danger"
    | "warning"
    | "bordered"
    | "link"
    | "gray-link"
    | "success"
    | "draft"
    | "white";

export interface IButtonProps {
    variant?: ButtonVariantTypes;

    size?: "small" | "large";
    type?: "submit" | "reset" | "button";

    transparent?: boolean;
    disabled?: boolean;

    isBusy?: boolean;

    className?: string;

    icon?: React.ReactNode;
    endIcon?: React.ReactNode;

    noMinWidth?: boolean;

    to?: string;
    testId?: string;

    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onMouseDown?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

    title?: string;

    id?: string;

    iconOnlyMobile?: boolean;
    innerRef?: React.Ref<HTMLButtonElement>;
}

const Button: React.FC<React.PropsWithChildren<IButtonProps>> = (props) => {
    const {
        transparent,
        disabled,
        isBusy,
        to,
        icon,
        endIcon,
        noMinWidth,
        title,
        id,
        iconOnlyMobile,
        testId,
        innerRef,
    } = props;

    const variant = props.variant ?? "blue";
    const size = props.size ?? "large";
    const type = props.type ?? "button";

    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        if (isBusy) {
            setShowSpinner(true);
        }

        // Show loader a bits longer to avoid loading flash
        if (!isBusy && showSpinner) {
            const timeout = setTimeout(() => {
                setShowSpinner(false);
            }, TIMEOUT_MS);

            // Don’t forget to clear the timeout
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [isBusy, showSpinner]);

    const className = useMemo(() => {
        const result: string[] = ["btn"];

        if (size === "small") {
            result.push("btn__size--small");
        }

        result.push(`btn__${variant}`);

        if (transparent) {
            result.push("btn-transparent");
        }

        if (icon && iconOnlyMobile) {
            result.push("btn-icon-only-mobile");
        }

        if (noMinWidth) {
            result.push("btn-no-min-width");
        }

        if (props.className) {
            result.push(props.className);
        }

        return result.join(" ");
    }, [
        size,
        variant,
        transparent,
        icon,
        noMinWidth,
        props.className,
        iconOnlyMobile,
    ]);

    const showIcon = !!icon;
    const showEndIcon = !!endIcon;

    return (
        <LinkWrapper to={to}>
            <button
                id={id}
                type={type}
                data-testid={testId}
                contentEditable={false}
                disabled={disabled}
                className={className}
                onClick={props.onClick}
                onMouseDown={props.onMouseDown}
                title={title}
                ref={innerRef}
            >
                {showSpinner ? (
                    <Spinner animation="border" size="sm" as="span" />
                ) : (
                    showIcon && <span className="btn-icon">{icon}</span>
                )}

                {!!props.children && (
                    <span className="btn-content">{props.children}</span>
                )}

                {showEndIcon && <span className="btn-icon">{endIcon}</span>}
            </button>
        </LinkWrapper>
    );
};

export default Button;
