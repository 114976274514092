import React from "react";

import { FaPaperclip, FaRegFileAlt, FaUndo } from "react-icons/fa";
import { connect } from "react-redux";

import i18n from "i18next";
import { v4 as uuidv4 } from "uuid";

import k from "i18n/keys";

import IAttachmentFormData from "../../../common/viewModels/IAttachmentFormData";
import IAttachment from "../../../models/IAttachment";
import { AttachmentType } from "../../../models/enums/AttachmentType";
import { showAttachmentForm } from "../../../store/attachments/actions";
import { DownloadFileLink } from "../download-file-link/DownloadFileLink";
import SortableListWithHandle from "../sortableLists/SortableListWithHandle";
import { IValueLabelItem } from "common/IValueLabelItem";
import Button from "common/components/buttons/Button";
import IconButton from "common/components/buttons/icon-button/IconButton";
import { DeleteIcon, UndoIcon } from "common/components/icons";
import { EActivityType } from "common/components/input-components/EActivityType";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import InputComponent from "common/components/input-components/InputComponent";

interface IProps {
    attachments: IAttachment[];

    itemClassName?: string;

    showAttachmentForm: (
        type: AttachmentType,
    ) => Promise<IAttachmentFormData | undefined>;

    onSave?: (attachments: IAttachment[]) => void;
}

interface IState {
    selectedTypeId: AttachmentType;
}

const AttachmentName = ({ attachment }: { attachment: IAttachment }) => {
    return attachment.isDeleted ? (
        <del>{attachment.name}</del>
    ) : (
        <React.Fragment>{attachment.name}</React.Fragment>
    );
};

class SortableAttachmentList extends React.Component<IProps, IState> {
    public readonly state: IState = {
        selectedTypeId: AttachmentType.None,
    };

    private options: IValueLabelItem<string, string>[] = [
        { value: String(AttachmentType.None), label: i18n.t(k.ADD_ATTACHMENT) },
        { value: String(AttachmentType.Link), label: i18n.t(k.LINK) },
        { value: String(AttachmentType.File), label: i18n.t(k.FILE) },
    ];

    public onSelectForm = async (e: IInputChangeEvent<string>) => {
        const selectedType = e.value;

        const type = Number(selectedType);

        this.setState({
            selectedTypeId: type,
        });

        const result = await this.props.showAttachmentForm(type);

        this.setState({
            selectedTypeId: AttachmentType.None,
        });

        if (result && this.props.onSave) {
            const attachmentName = result.extension
                ? `${result.name}.${result.extension}`
                : result.name;

            const attachment: IAttachment = {
                id: uuidv4(),
                isNew: true,
                attachmentType: type,
                name: attachmentName,
                content: result.content,
                index: this.props.attachments.length + 1,
            };

            let attachments: IAttachment[];

            if (this.props.attachments.find((x) => x.id === attachment.id)) {
                attachments = this.props.attachments.map((x) => {
                    if (x.id === attachment.id) {
                        return attachment;
                    }

                    return x;
                });
            } else {
                attachments = this.props.attachments.concat(attachment);
            }

            this.props.onSave(attachments);
        }
    };

    public handleChangeDelete = (id: string, isDeleted?: boolean) => () => {
        if (this.props.onSave) {
            this.props.onSave(
                this.props.attachments.map((x) => {
                    if (x.id !== id) {
                        return x;
                    }

                    return {
                        ...x,
                        isDeleted,
                    };
                }),
            );
        }
    };

    public render() {
        const { attachments, itemClassName } = this.props;

        const { selectedTypeId } = this.state;

        const hasAttachments = attachments.length > 0;

        const DeleteButton = ({ attachment }: { attachment: IAttachment }) =>
            attachment.isDeleted ? (
                <IconButton onClick={this.handleChangeDelete(attachment.id)}>
                    <UndoIcon />
                </IconButton>
            ) : (
                <IconButton
                    onClick={this.handleChangeDelete(attachment.id, true)}
                >
                    <DeleteIcon />
                </IconButton>
            );

        return (
            <div className="attachment-list" data-testid="attachment-list">
                <InputComponent
                    inputType={EActivityType.Dropdown}
                    wrapperLabel={i18n.t(k.ATTACHMENTS)}
                    boldLabel
                    value={String(selectedTypeId)}
                    options={this.options}
                    onChange={this.onSelectForm}
                />

                {hasAttachments && (
                    <SortableListWithHandle
                        className={`sortable-attachment-item${
                            itemClassName ? ` ${itemClassName}` : ""
                        }`}
                        items={attachments}
                        onSorted={this.props.onSave}
                        render={(x) => {
                            if (x.attachmentType === AttachmentType.File) {
                                if (x.isNew || x.sourceId) {
                                    return (
                                        <React.Fragment>
                                            <FaRegFileAlt />

                                            <AttachmentName attachment={x} />

                                            <DeleteButton attachment={x} />
                                        </React.Fragment>
                                    );
                                }

                                return (
                                    <React.Fragment>
                                        <FaRegFileAlt />
                                        <DownloadFileLink
                                            id={x.id}
                                            name={x.name}
                                        >
                                            <AttachmentName attachment={x} />
                                        </DownloadFileLink>

                                        <DeleteButton attachment={x} />
                                    </React.Fragment>
                                );
                            }

                            return (
                                <React.Fragment>
                                    <FaPaperclip />
                                    <a href={`${x.content}`} target="_blank">
                                        <AttachmentName attachment={x} />
                                    </a>

                                    <DeleteButton attachment={x} />
                                </React.Fragment>
                            );
                        }}
                    />
                )}
            </div>
        );
    }
}

const actions = {
    showAttachmentForm,
};

export default connect(null, actions)(SortableAttachmentList);
