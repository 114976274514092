import "./Content.scss";

import React, { useContext, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { v4 as uuidv4 } from "uuid";

import k from "i18n/keys";

import TemplateFormContext from "../../context/TemplateFormContext";
import { EParentType } from "common/components/listed-content/components/SidePanel/List/components/ListItems/ListItems";
import { arrayMoveTo } from "common/utils/arrayUtils";
import EditableStep from "components/steps/components/editable-step/EditableStep";
import NoStepsPlaceholder from "components/templates/components/NoStepsPlaceholder";
import IStep from "models/IStep";

const stepsReq = "steps.required";

const Content: React.FC = (props) => {
    const {
        steps,
        sectionRefs,
        setSteps,
        onHaveChanges,
        isArchived,
        errors,
        setErrors,
        handleOnCopyStep,
        scrollToTarget,
        scrollToAfterNextUpdate,
        setScrollToAfterNextUpdate,
    } = useContext(TemplateFormContext);

    const { t } = useTranslation();

    const handleOnAddStep = () => {
        if (setSteps && steps) {
            const newStep: IStep = {
                id: uuidv4(),
                stepVersionId: uuidv4(),
                name: t(k.NEW_STEP),
                description: "",
                isLocal: true,
                isDraft: true,
                index: steps.ids.length,
                teamIds: [],
                equipmentIds: [],
                hasPublishedVersion: false,
                hasNewVersion: false,
                isTeamEveryone: true,
                version: 0,
                isAdded: true,
            };

            setSteps((prev) => ({
                ...prev,
                ids: [...prev.ids, newStep.id],
                values: {
                    ...prev.values,
                    [newStep.id]: newStep,
                },
            }));

            if (errors?.[stepsReq]) {
                setErrors((prev) => ({
                    ...prev,
                    [stepsReq]: undefined,
                }));
            }

            onHaveChanges(true);
        }
    };

    const handleOnDeleteStep = (stepId: string) => {
        if (setSteps && steps) {
            setSteps((prev) => {
                const step = prev.values[stepId];

                if (step) {
                    return {
                        ...prev,
                        values: {
                            ...prev.values,
                            [stepId]: {
                                ...step,
                                isDeleted: !step.isDeleted,
                            },
                        },
                    };
                }

                return prev;
            });
            onHaveChanges(true);
        }
    };

    const handleOnSorted = (
        newIndex: number,
        oldIndex: number,
        stepId: string,
    ) => {
        if (setSteps && steps) {
            const sortedIds = arrayMoveTo(steps.ids, oldIndex, newIndex);

            setSteps((prev) => ({ ...prev, ids: sortedIds }));
            onHaveChanges(true);

            setScrollToAfterNextUpdate(stepId);
        }
    };

    useEffect(() => {
        if (scrollToAfterNextUpdate && sectionRefs && scrollToTarget) {
            scrollToTarget({
                targetId: scrollToAfterNextUpdate,
                sectionRefs,
            });

            setScrollToAfterNextUpdate(undefined);
        }
    }, [scrollToAfterNextUpdate]);

    return (
        <>
            {steps && sectionRefs && steps.ids.length ? (
                steps.ids.map((id, index) => {
                    const step = steps.values[id];
                    const stepRef = sectionRefs[id];

                    if (step && stepRef && setSteps && setErrors) {
                        return (
                            <EditableStep
                                key={id}
                                stepRef={stepRef}
                                index={index}
                                lastIndex={steps.ids.length - 1}
                                step={step}
                                errors={errors}
                                isArchived={isArchived}
                                parentType={EParentType.template}
                                onDeleteStep={handleOnDeleteStep}
                                onCopyStep={handleOnCopyStep}
                                onMoveStep={handleOnSorted}
                                setSteps={setSteps}
                                setErrors={setErrors}
                                onHaveChanges={onHaveChanges}
                            />
                        );
                    }
                })
            ) : (
                <NoStepsPlaceholder
                    onAddStep={handleOnAddStep}
                    disabled={isArchived}
                />
            )}
        </>
    );
};

export default Content;
