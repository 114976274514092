import "./HeaderFilters.scss";

import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import Accordion from "common/components/accordion/Accordion";
import IconButton from "common/components/buttons/icon-button/IconButton";
import { FilterIcon, SearchIcon } from "common/components/icons";
import { EActivityType } from "common/components/input-components/EActivityType";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import InputComponent from "common/components/input-components/InputComponent";
import Checkbox from "common/components/input-components/checkbox/Checkbox";
import ImprovementStatusDropdownTree from "common/components/input-components/dropdown-tree/ImprovementStatusDropdownTree";
import { DEFAULT_DELAY_INTERVAL_MS } from "common/utils/time";
import { ImpactMultiselect } from "components/common/multiselect-dropdowns/ImpactMultiselect";
import { ImprovementFormMultiselect } from "components/common/multiselect-dropdowns/ImprovementFormMultiselect";
import { ImpactGradingEnum } from "components/impact-grading-page/api/ImpactGradingEnum";
import IDeviationPageFilter from "http/filters/IDeviationPageFilter";
import { ClosureReasonEnum } from "models/enums/ClosureReasonEnum";
import { DynamicImprovementStatus } from "models/enums/DynamicImprovementStatus";

interface IProps {
    currentFilter?: IDeviationPageFilter;

    selectedId?: string;

    onChangeFilters: (changed: Partial<IDeviationPageFilter>) => void;

    onClearSelectedId: () => void;
}

const HeaderFilters: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { selectedId, currentFilter } = props;

    const [searchBy, setSearchBy] = useState<string>("");

    const [showFilter, setShowFilter] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        const delay = setTimeout(() => {
            props.onChangeFilters({ keywordSearch: searchBy });
        }, DEFAULT_DELAY_INTERVAL_MS);

        return () => clearTimeout(delay);
    }, [searchBy]);

    const handleOnChangeStatusTreeFilter = (
        newStatuses: DynamicImprovementStatus[],
        newClosureReasons: ClosureReasonEnum[],
    ) => {
        props.onChangeFilters({
            status: newStatuses,
            closureReasons: newClosureReasons,
        });
    };

    const handleOnChangeImpactFilters = (newImpacts: ImpactGradingEnum[]) => {
        props.onChangeFilters({
            impacts: newImpacts,
        });
    };

    const handleOnChangeImprovementFormFilters = (
        newImprovementForms: string[],
    ) => {
        props.onChangeFilters({
            improvementForms: newImprovementForms,
        });
    };

    const handleChangeSearchKeywordFilter = (e: IInputChangeEvent<string>) => {
        setSearchBy(e.value);
    };

    const toggleShowFilter = () => {
        setShowFilter((prev) => !prev);
    };

    return (
        <>
            <div className="improvements__header-commands hide-in-mobile">
                {selectedId ? (
                    <div className="search-filter--icon_cell">
                        <label>&nbsp;</label>

                        <div className="improvements--details-filter">
                            <Checkbox
                                value={true}
                                label={t(k.FILTER_BY_ID_UNCHECK_TO_SHOW_ALL)}
                                onChange={props.onClearSelectedId}
                            />
                        </div>
                    </div>
                ) : (
                    <React.Fragment>
                        <InputComponent
                            inputType={EActivityType.Textfield}
                            id="filter.searchBy"
                            name="searchBy"
                            value={currentFilter?.keywordSearch ?? ""}
                            placeholder={t(k.SEARCH)}
                            size="large"
                            testId="improvementSearchField"
                            wrapperIcon={<SearchIcon />}
                            hideIcon
                            onChange={handleChangeSearchKeywordFilter}
                        />
                    </React.Fragment>
                )}

                {props.children}
            </div>
            <div className="improvements__header-commands show-in-mobile">
                <Accordion
                    header={
                        <InputComponent
                            inputType={EActivityType.Textfield}
                            id="filter.searchBy--mobile"
                            name="searchBy"
                            value={currentFilter?.keywordSearch ?? ""}
                            placeholder={t(k.SEARCH)}
                            size="large"
                            testId="improvementSearchField"
                            wrapperIcon={<SearchIcon />}
                            hideIcon
                            onChange={handleChangeSearchKeywordFilter}
                        />
                    }
                    icon={
                        <IconButton
                            className={showFilter ? "icon-button--active" : ""}
                            onClick={toggleShowFilter}
                            testId="ImprovementsMobileFilterToggle"
                        >
                            <FilterIcon />
                        </IconButton>
                    }
                    manualToggle
                    open={showFilter}
                >
                    <div className="improvements-moblie-filter">
                        <ImprovementStatusDropdownTree
                            selectedClosureReasons={
                                currentFilter?.closureReasons ?? null
                            }
                            selectedStatuses={currentFilter?.status ?? null}
                            hasSelectedItems={
                                !!currentFilter?.status?.length ||
                                !!currentFilter?.closureReasons?.length
                            }
                            onChange={handleOnChangeStatusTreeFilter}
                            wrapperLabel={t(k.FILTER_BY_STATUS)}
                            showRemove
                        />

                        <ImpactMultiselect
                            wrapperLabel={t(k.FILTER_BY_IMPACT)}
                            onChange={handleOnChangeImpactFilters}
                        />

                        <ImprovementFormMultiselect
                            wrapperLabel={t(k.FILTER_BY_IMPROVEMENT_FORM)}
                            closeMenuOnSelect={false}
                            onChange={handleOnChangeImprovementFormFilters}
                        />
                    </div>
                </Accordion>

                {props.children}
            </div>
        </>
    );
};

export default HeaderFilters;
