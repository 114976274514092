import "./ValidationLabel.scss";

import React from "react";

import { useTranslation } from "react-i18next";

import { getLocaleString } from "i18n/components/GetLocaleString";

interface IProps {
    id?: string;
    darkColor?: boolean;
    errors?: string[] | string | React.ReactNode;
    testId?: string;
}

const ValidationLabel = (props: React.PropsWithChildren<IProps>) => {
    const { errors, darkColor, testId = "validation-label", id } = props;

    const { t } = useTranslation();

    return errors ? (
        <div
            id={id}
            className={"validation-label" + (darkColor ? " dark-color" : "")}
            data-testid={testId}
        >
            {Array.isArray(errors)
                ? errors.map((error, index) => (
                      <div key={index}>{getLocaleString(t, error)}</div>
                  ))
                : getLocaleString(t, errors as string)}
        </div>
    ) : null;
};

export default ValidationLabel;
