import "./ImprovementsPage.scss";

import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { FaRegFileExcel } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import k from "i18n/keys";

import { downloadDeviationsAsync } from "../../common/utils/download";
import IDeviationPageFilter from "../../http/filters/IDeviationPageFilter";
import IImprovementFilter from "./api/IImprovementFIlter";
import { getImprovementData } from "./api/hooks";
import HeaderFilters from "./components/HeaderFilters";
import { SelectImprovementImportTemplate } from "./components/import-grid/SelectImprovementImportTemplate";
import ImprovementsTable, {
    IMPROVEMENTS_DEFAULT_SORT,
    IMPROVEMENTS_TABLE_PAGE_SIZE,
} from "./components/tables/ImprovementsTable";
import { ButtonGroup } from "common/components/buttons";
import Button from "common/components/buttons/Button";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import {
    IShowModalPayload,
    ModalTypes,
} from "common/components/modal-manager/api/IModalManager";
import { PageWrapper } from "common/components/page-wrapper";
import { ImprovementProcessChartImpactType } from "components/process-chart/api/improvements/ImprovementProcessChartImpactType";
import IDeviationFilter from "http/filters/IDeviationFilter";
import { DynamicImprovementStatus } from "models/enums/DynamicImprovementStatus";
import { IAppState } from "store/IAppState";
import { FiPlus } from "react-icons/fi";

interface IProps {
    showImprovementsForId?: string;
    showImprovementsForDetails?: IImprovementFilter;
}

interface IPageParamTypes {
    id?: string;
}

const ImprovementsPage = (props: IProps) => {
    const { showImprovementsForId, showImprovementsForDetails } = props;

    const modalManagerContext = useContext(ModalManagerContext);

    const { t } = useTranslation();

    const location = useLocation();

    const navigate = useNavigate();

    const canImport = useSelector((appState: IAppState) => {
        const { roles, profile } = appState.authViewState;

        if (profile?.appFeatures?.bulkImport) {
            return roles.ADMINISTRATOR || roles.DEVIATION_MANAGER;
        }
    });

    const { id: improvementId } = useParams<keyof IPageParamTypes>();
    const [initialSelectedId, setInitialSelectedId] = useState(improvementId);
    const [selectedId, setSelectedId] = useState(improvementId);

    const startingFilterStatusValue =
        showImprovementsForId != undefined &&
        showImprovementsForDetails?.impactType !== undefined &&
        showImprovementsForDetails?.impactType !==
            ImprovementProcessChartImpactType.None
            ? [
                  DynamicImprovementStatus.Registered,
                  DynamicImprovementStatus.Analysing,
                  DynamicImprovementStatus.Acting,
                  DynamicImprovementStatus.Verifying,
              ]
            : undefined;

    const [requestFilter, setRequestFilter] = useState<IDeviationPageFilter>({
        page: 1,
        pageSize: IMPROVEMENTS_TABLE_PAGE_SIZE,
        ...IMPROVEMENTS_DEFAULT_SORT,
        keywordSearch: "",

        id: initialSelectedId ?? showImprovementsForId,

        showImprovementsForId,
        status: startingFilterStatusValue,
    });

    const {
        data: items = {
            ids: [],
            values: {},
        },
        isLoading,
        refetch: refetchItems,
    } = getImprovementData(true, showImprovementsForDetails, requestFilter);

    const [isImportModalVisible, setIsImportModalVisible] = useState(false);

    const fetchData = async (
        showImprovementsForId?: string,
        changedFilters?: Partial<IDeviationPageFilter>,
    ) => {
        const requestFilter: IDeviationPageFilter = {
            page: 1,
            pageSize: IMPROVEMENTS_TABLE_PAGE_SIZE,

            id: initialSelectedId ?? showImprovementsForId,

            ...changedFilters,

            showImprovementsForId,
        };

        setRequestFilter((prev) => {
            if (prev) {
                return { ...prev, ...requestFilter };
            }
            return {
                ...requestFilter,
            };
        });
    };

    useEffect(() => {
        if (selectedId !== improvementId) {
            navigate(
                selectedId ? `/improvements/${selectedId}` : `/improvements`,
            );
        }
    }, [selectedId]);

    const clearSelectedIdFilter = () => {
        setInitialSelectedId(undefined);

        setSelectedId(undefined);

        fetchData(showImprovementsForId, { id: undefined });
    };

    const handleChangeTableFilter = (filter: Partial<IDeviationFilter>) => {
        fetchData(showImprovementsForId, filter);
    };

    const handleNewImprovement = () => {
        const payload: IShowModalPayload = {
            callerAddress: location.pathname,
            type: ModalTypes.select_improvement_form,
        };

        modalManagerContext.onShowModal(payload);
    };

    const handleShowImportModal = () => {
        setIsImportModalVisible(true);
    };

    const handleHideImportModal = (amount?: number) => {
        setIsImportModalVisible(false);

        if (amount && amount > 0) {
            refetchItems();

            toast.success(
                t(k.IMPORT_FROM_EXCEL_SUCCESS_MESSAGE_WITH_AMOUNT, { amount }),
                {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },
            );
        }
    };

    const handleExportExcel = async () => {
        await downloadDeviationsAsync(requestFilter);
    };

    const handlePageChange = () => {
        setSelectedId(initialSelectedId);
    };

    return (
        <PageWrapper
            title={
                showImprovementsForId ? undefined : t(k.NAV_NODE_IMPROVEMENTS)
            }
            rightOfTitle={
                showImprovementsForId ? undefined : (
                    <ButtonGroup>
                        <Button
                            onClick={handleNewImprovement}
                            size="small"
                            testId="addNewImprovementBtn"
                            icon={<FiPlus />}
                        >
                            {t(k.NEW_IMPROVEMENT)}
                        </Button>

                        {canImport && (
                            <Button
                                onClick={handleShowImportModal}
                                icon={<FaRegFileExcel />}
                                size="small"
                                transparent
                            >
                                {t(k.IMPORT)}
                            </Button>
                        )}
                    </ButtonGroup>
                )
            }
            subtitle={
                showImprovementsForId ? undefined : t(k.IMPROVEMENTS_PAGE_LABEL)
            }
            rightSideContent={
                <HeaderFilters
                    currentFilter={requestFilter}
                    selectedId={initialSelectedId}
                    onChangeFilters={handleChangeTableFilter}
                    onClearSelectedId={clearSelectedIdFilter}
                >
                    <div className="hide-in-mobile">
                        <Button
                            onClick={handleExportExcel}
                            noMinWidth
                            transparent
                            className="button__excel"
                            title={t(k.EXPORT_TO_EXCEL)}
                        >
                            <FaRegFileExcel />
                        </Button>
                    </div>
                </HeaderFilters>
            }
            className={`improvements-page`}
        >
            <ImprovementsTable
                items={items}
                name="improvements-table"
                isLoading={isLoading}
                currentFilter={requestFilter}
                onChangeTableFilters={handleChangeTableFilter}
                onRefetchItems={refetchItems}
                onPageChange={handlePageChange}
            />

            {isImportModalVisible && (
                <SelectImprovementImportTemplate
                    onHide={handleHideImportModal}
                />
            )}
        </PageWrapper>
    );
};

export default ImprovementsPage;
