import "./SortableList.scss";

import React, { ReactNode, RefObject, useEffect, useState } from "react";

import { FaBars } from "react-icons/fa";
import {
    SortEnd,
    SortEvent,
    SortStart,
    SortableContainer,
    SortableContainerProps,
    SortableElement,
    SortableElementProps,
    SortableHandle,
} from "react-sortable-hoc";

import classNames from "classnames";

interface IDragHandleProps {
    className?: string;
}

export const DragHandle = SortableHandle<IDragHandleProps>(
    (props: IDragHandleProps) => {
        const className = classNames("sortable-list--handle", props.className);
        return (
            <div className={className}>
                <FaBars />
            </div>
        );
    },
);

export interface ISortableItemProps {
    id?: string;
    className?: string;
    itemRef?: RefObject<HTMLDivElement>;
    children?: React.ReactNode;
    useHandle?: boolean;
    hideDragHandleOnMobile?: boolean;
    itemDisabled?: boolean;
    orderNumber?: number;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const SortableItem = SortableElement<ISortableItemProps>(
    (props: ISortableItemProps) => {
        const {
            id,
            children,
            className,
            useHandle,
            itemDisabled,
            orderNumber,
            hideDragHandleOnMobile,
            itemRef,
        } = props;

        const showHandle = itemDisabled ? false : useHandle;

        return (
            <div
                id={id}
                ref={itemRef}
                className={`sortable-list--item ${className ?? ""}`}
                onClick={props.onClick}
            >
                {showHandle && (
                    <DragHandle
                        className={
                            hideDragHandleOnMobile
                                ? "hide-handle-on-mobile"
                                : undefined
                        }
                    />
                )}

                {orderNumber && (
                    <div className="sortable-list--index">{orderNumber}.</div>
                )}

                {children}
            </div>
        );
    },
);

export const Container = SortableContainer<{
    children: ReactNode;
    className?: string;
    containerRef?: RefObject<HTMLDivElement>;
}>(
    (props: {
        children: ReactNode;
        className?: string;
        containerRef?: RefObject<HTMLDivElement>;
    }) => (
        <div
            ref={props.containerRef}
            className={`sortable-list ${props.className ?? ""}`}
        >
            {props.children}
        </div>
    ),
);

interface IProps<K> extends SortableContainerProps {
    itemIds: string[];

    listId?: K;

    className?: string;

    itemClassName?: string;

    disabled?: boolean;

    hideOrder?: boolean;

    hideHandleOnMobile?: boolean;

    render: (item: string, index: number) => React.ReactNode;

    onSorted?: (params: SortEnd, listId?: K) => void;
}

const SortableList = <T, K extends keyof T>(props: IProps<K>) => {
    const {
        itemIds,
        className,
        itemClassName,
        useDragHandle = false,
        disabled = false,
        hideOrder = false,
        listId,
        helperClass,
    } = props;

    const [isSorting, setIsSorting] = useState(false);

    useEffect(() => {
        if (isSorting) {
            document.body.classList.add("grabbing-sort-item");
        } else {
            document.body.classList.remove("grabbing-sort-item");
        }
    }, [isSorting]);

    const handleOnSortStart = (params: SortStart, event: SortEvent) => {
        if (event instanceof MouseEvent) {
            setIsSorting(true);
        }
    };

    const handleOnSortEnd = (params: SortEnd) => {
        props.onSorted?.(params, listId);
        setIsSorting(false);
    };

    const shouldCancelStart = () => {
        return disabled;
    };

    const _helperClass = helperClass
        ? `sortable-list--item--helper ${helperClass}`
        : "sortable-list--item--helper";

    return (
        <Container
            {...(props as SortableContainerProps)}
            helperClass={_helperClass}
            onSortStart={handleOnSortStart}
            onSortEnd={handleOnSortEnd}
            useDragHandle={useDragHandle}
            className={className}
            shouldCancelStart={shouldCancelStart}
        >
            {itemIds.map((item, index) => {
                const itemContent = props.render(item, index);

                if (itemContent == undefined) {
                    return null;
                }

                return (
                    <SortableItem
                        key={item}
                        index={index}
                        orderNumber={hideOrder ? undefined : index + 1}
                        className={itemClassName}
                        useHandle={useDragHandle}
                        hideDragHandleOnMobile={props.hideHandleOnMobile}
                        itemDisabled={disabled}
                    >
                        {itemContent}
                    </SortableItem>
                );
            })}
        </Container>
    );
};

export default SortableList;
