import { useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import k from "i18n/keys";

import { useRegisterSW } from "virtual:pwa-register/react";

const intervalMS = 60 * 60 * 100;
export const configureVitePWA = () => {
    const { t } = useTranslation();
    if (typeof window.Cypress === "undefined") {
        const updateInitiatedRef = useRef(false);

        const {
            needRefresh: [needRefresh, setNeedRefresh],
            updateServiceWorker,
        } = useRegisterSW({
            immediate: true,
            onRegisteredSW(swUrl, r) {
                setTimeout(() => {
                    updateServiceWorker(true).catch((error) =>
                        console.error(
                            "SW update attempt on registration failed:",
                            error,
                        ),
                    );
                }, 100);
                r &&
                    setInterval(async () => {
                        if (!(!r.installing && navigator)) return;

                        if ("connection" in navigator && !navigator.onLine)
                            return;

                        try {
                            const resp = await fetch(swUrl, {
                                cache: "no-store",
                                headers: {
                                    cache: "no-store",
                                    "cache-control": "no-cache",
                                },
                            });
                            if (resp?.status === 200) {
                                await r.update();
                            }
                        } catch (error) {
                            console.error(
                                "Failed to fetch service worker:",
                                error,
                            );
                        }
                    }, intervalMS);
            },
        });

        useEffect(() => {
            if (needRefresh && !updateInitiatedRef.current) {
                updateInitiatedRef.current = true;
                toast.success(
                    t(k.NEW_VERSION_AVAILABLE_UPDATING_IN_30_SECONDS),
                    {
                        autoClose: 30000,
                        pauseOnHover: false,
                        closeButton: false,
                        onClick: () => {
                            updateInitiatedRef.current = false;
                            updateServiceWorker(true);
                        },
                        onClose: () => {
                            updateInitiatedRef.current = false;
                            updateServiceWorker(true);
                        },
                    },
                );
            }
        }, [needRefresh]);
    }
};
