import "./Content.scss";

import { PropsWithChildren } from "react";

import classNames from "classnames";

export interface IContentInputProps {
    contentRef?: React.RefObject<HTMLDivElement>;
    className?: string;
    onScroll?: (event?: React.UIEvent<HTMLDivElement>) => void;
}

const Content = (props: PropsWithChildren<IContentInputProps>) => {
    const className = classNames("listed-content__content", props.className);
    return (
        <div
            className={className}
            ref={props.contentRef}
            onScroll={props.onScroll}
        >
            {props.children}
        </div>
    );
};

export default Content;
