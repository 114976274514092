import "./ConfigurationStep.scss";

import { useContext, useEffect } from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import {
    useSelectedDynamicRowValuesInitialization,
    useSelectedValuesInitialization,
} from "./configurationStep.hooks";
import {
    getKeysOrderedByIndex,
    handleAddUserRowUtil,
    handleChangeNotifyUserSetUtil,
    handleChangeUsersByUserSetsUtil,
    handleClickUtil,
    handleDeleteRowUtil,
    updateConfiguration,
} from "./utils/configurationStep.utils";
import ConfigurationTable from "common/components/configuration-table/ConfigurationTable";
import { InfoIcon, WarningIcon } from "common/components/icons";
import StepCardWrapper from "common/components/step-card/StepCardWrapper";
import ImprovementFormContext from "components/improvement-forms/components/ImprovementForm/context/ImprovementFormContext";
import { CONFIGURATION_STEP_ID } from "components/improvement-forms/components/ImprovementForm/context/ImprovementFormData";

const ConfigurationStep: React.FC = (props) => {
    const {
        sectionRefs,
        onHaveChanges,
        configuration,
        setConfiguration,
        isArchived,
        errors,
        haveChanges,
    } = useContext(ImprovementFormContext);

    const { t, i18n } = useTranslation();

    const [selectedValues, setSelectedValues] = useSelectedValuesInitialization(
        i18n,
        configuration,
        haveChanges,
    );

    const [selectedDynamicValues, setSelectedDynamicValues] =
        useSelectedDynamicRowValuesInitialization(
            i18n,
            configuration,
            haveChanges,
        );

    const handleClick = (row: string, column: string, value: boolean) => {
        handleClickUtil(selectedValues, row, column, value, setSelectedValues);
        onHaveChanges(true);
    };

    const handleAddUserRow = () => {
        handleAddUserRowUtil(selectedDynamicValues, setSelectedDynamicValues);
        onHaveChanges(true);
    };

    const handleDeleteRow = (id: string) => {
        handleDeleteRowUtil(
            id,
            selectedDynamicValues,
            setSelectedDynamicValues,
        );
        onHaveChanges(true);
    };

    const handleChangeNotifyUserSet = (id: string, value: boolean) => {
        handleChangeNotifyUserSetUtil(
            selectedDynamicValues,
            id,
            value,
            setSelectedDynamicValues,
        );
        onHaveChanges(true);
    };

    const handleChangeUsersByUserSets = (id: string, users: string[]) => {
        handleChangeUsersByUserSetsUtil(
            selectedDynamicValues,
            id,
            users,
            setSelectedDynamicValues,
        );

        onHaveChanges(true);
    };

    useEffect(() => {
        if (selectedValues && haveChanges) {
            const newConfig = updateConfiguration(
                selectedValues,
                selectedDynamicValues,
                configuration?.templateVersionId,
            );
            setConfiguration(newConfig);
        }
    }, [selectedValues, selectedDynamicValues]);

    const error =
        errors?.["template.responsibleUsers"] ||
        errors?.["template.involvedUsers"];

    const errorGeneralConfiguration = errors?.["template.responsibleUsers"];
    const errorInvolvedUsersConfiguration = errors?.["template.involvedUsers"];

    return (
        <StepCardWrapper
            id={CONFIGURATION_STEP_ID}
            dataId={CONFIGURATION_STEP_ID}
            stepRef={sectionRefs && sectionRefs[CONFIGURATION_STEP_ID]}
            testId={`editable-step-${CONFIGURATION_STEP_ID}`}
            titleContent={
                <div
                    className="editable-step--header--title"
                    data-testid="step-title"
                >
                    {t(k.CONFIGURATION)}
                    {errorGeneralConfiguration && (
                        <WarningIcon
                            active
                            tooltip={{
                                id: "template.responsibleUsers",
                                message: t(errorGeneralConfiguration),
                                placement: "bottom",
                            }}
                        />
                    )}
                </div>
            }
            invalid={!!error}
            bodyContent={
                <>
                    <ConfigurationTable
                        tableHeader={`${t(k.ROLE)}/${t(k.SETTINGS)}`}
                        staticRows={[
                            k.OWNER_FOR_SELECTED_DISCOVERED_IN_PROCESS,
                            k.OWNER_FOR_SELECTED_ORIGINATED_IN_PROCESS,
                            k.REPORTING_USERS_MANAGER,
                        ]}
                        optionalColumns={[
                            { name: k.RESPONSIBLE, type: "radio" },
                            { name: k.INVOLVED, type: "checkbox" },
                            {
                                name: k.NOTIFY,
                                type: "checkbox",
                            },
                        ]}
                        selectedValues={selectedValues}
                        disabled={isArchived}
                        onClick={handleClick}
                        onAddUserRow={handleAddUserRow}
                    />
                    <ConfigurationTable
                        tableHeader={
                            <>
                                {t(
                                    k.IMPROVEMENT_CONFIGURATION_INVOLVED_TABLE_HEADER,
                                )}
                                <InfoIcon
                                    tooltip={{
                                        id: "notify-tooltip",
                                        message: t(
                                            k.INVOLVED_USERS_HEADER_TOOLTIP,
                                        ),
                                        placement: "bottom",
                                    }}
                                    size="small"
                                />
                            </>
                        }
                        staticRows={[]}
                        optionalColumns={[
                            {
                                name: k.NOTIFY,
                                type: "checkbox",
                                tooltip: (
                                    <InfoIcon
                                        tooltip={{
                                            id: "notify-tooltip",
                                            message: t(
                                                k.INVOLVED_USERS_TOOLTIP,
                                            ),
                                            placement: "bottom",
                                        }}
                                        size="small"
                                    />
                                ),
                            },
                        ]}
                        dynamicUserRows={getKeysOrderedByIndex(
                            selectedDynamicValues ?? {},
                        )}
                        selectedValues={selectedValues}
                        selectedDynamicValues={selectedDynamicValues}
                        disabled={isArchived}
                        errorMessage={
                            errorInvolvedUsersConfiguration &&
                            t(errorInvolvedUsersConfiguration)
                        }
                        onClick={handleClick}
                        onAddUserRow={handleAddUserRow}
                        onClickNotifyUserSet={handleChangeNotifyUserSet}
                        onChangeUsers={handleChangeUsersByUserSets}
                        onDeleteRow={handleDeleteRow}
                    />
                </>
            }
        />
    );
};

export default ConfigurationStep;
