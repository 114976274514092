import "./ActivityCardWrapper.scss";

import { PropsWithChildren, RefObject } from "react";

import classNames from "classnames";

interface IActivityCardWrapperProps {
    dataTestId?: string;
    headerContent?: React.ReactNode;

    className?: string;
    headerClassName?: string;
    contentClassName?: string;
}

const ActivityCardWrapper = (
    props: PropsWithChildren<IActivityCardWrapperProps>,
) => {
    const { dataTestId, headerContent } = props;

    const className = classNames("activity-card-wrapper", props.className);
    const headerClassName = classNames(
        "activity-card-wrapper__header",
        props.headerClassName,
    );
    const contentClassName = classNames(
        "activity-card-wrapper__content",
        props.contentClassName,
    );

    return (
        <div className={className} data-testid={dataTestId}>
            {headerContent && (
                <div className={headerClassName}>{headerContent}</div>
            )}
            <div className={contentClassName}>{props.children}</div>
        </div>
    );
};

export default ActivityCardWrapper;
