import React, { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import { IKpiFormulaCalculations } from "../../kpi-form/api/IKpiFormulaCalculations";
import { lazyMinLoadTime } from "common/utils/lazyMinLoadTime";
import { MonthsKeys } from "i18n/keySets/MonthKeys";
import { Months } from "models/enums/Months";
import LazyLoadingFallback from "routes/components/LazyLoadingFallback";

const Plot = lazyMinLoadTime(() => import("common/components/plotly"));

interface IProps {
    values: IKpiFormulaCalculations;
    underThreshold: number;
    overThreshold: number;
    overTargetIsGood: boolean;
}

type DataPerYear = {
    x: string[];
    yTarget: number[];
    yCalculated: number[];
};

const GREEN_COLOR = "#0DB691";
const YELLOW_COLOR = "#FFC107";
const RED_COLOR = "#ff2929";
const COUNT_OF_MONTHS = 12;
const SECONDARY_LINE_OPACITY = 0.35;

const castPreparedData = (values: IKpiFormulaCalculations) => {
    const monthlyValuesPerYear = values.valuesByYear;

    const result = Object.entries(monthlyValuesPerYear).reduce<
        Record<number, DataPerYear>
    >((acc, [year, monthlyValues]) => {
        const mad =
            monthlyValues?.map((item) => item?.calculatedValue ?? 0) ?? [];

        acc[parseInt(year)] = {
            x: mad.map(
                (_, index) => MonthsKeys[(index % COUNT_OF_MONTHS) as Months],
            ),
            yCalculated: mad,
            yTarget: monthlyValues?.map((item) => item?.targetValue ?? 0) ?? [],
        };

        return acc;
    }, {});

    return result;
};

const KpiChart = (props: IProps) => {
    const { values, overTargetIsGood } = props;

    const { t } = useTranslation();

    const [preparedData, setPreparedData] = useState<
        Record<number, DataPerYear>
    >(castPreparedData(values));

    const getProgressLine = (
        x: string[],
        y: number[],
        isSecondary?: boolean,
        name?: string,
        group?: string,
    ) => {
        const line: Plotly.Data = {
            x,
            y,
            legendgroup: group,
            type: "scatter",
            mode: "lines",
            line: {
                color: "#152b6f",
                width: 1,
            },
            opacity: isSecondary ? SECONDARY_LINE_OPACITY : 1,
            name: name ?? "",
            showlegend: !!name,
        };

        return line;
    };

    const getDashedLine = (
        x: string[],
        y: number[],
        color?: string,
        isSecondary?: boolean,
        name?: string,
        group?: string,
    ) => {
        const line: Plotly.Data = {
            x,
            y,
            legendgroup: group,
            type: "scatter",
            mode: "lines",
            line: { dash: "dash", width: 1, color },
            opacity: isSecondary ? SECONDARY_LINE_OPACITY : 1,
            name: name ?? "",
            showlegend: !!name,
        };

        return line;
    };

    const getCorrectedThresholds = (targetValue: number) => {
        return {
            underThreshold:
                targetValue > 0 ? props.underThreshold : props.overThreshold,
            overThreshold:
                targetValue > 0 ? props.overThreshold : props.underThreshold,
        };
    };

    const getMarkers = (
        preparedDataPerYear: DataPerYear,
        isSecondary?: boolean,
        name?: string,
        year?: number,
        group?: string,
    ) => {
        const x = preparedDataPerYear.x;
        const y = preparedDataPerYear.yCalculated;

        const markerColors = preparedDataPerYear.yCalculated.map((value, i) => {
            const correctedThresholds = getCorrectedThresholds(
                preparedDataPerYear.yTarget[i],
            );

            if (
                value >=
                (preparedDataPerYear.yTarget[i] *
                    correctedThresholds.overThreshold) /
                    100
            ) {
                return overTargetIsGood ? GREEN_COLOR : RED_COLOR;
            } else if (
                value <=
                (preparedDataPerYear.yTarget[i] *
                    correctedThresholds.underThreshold) /
                    100
            ) {
                return overTargetIsGood ? RED_COLOR : GREEN_COLOR;
            } else {
                return YELLOW_COLOR;
            }
        });

        const markersPlot: Plotly.Data = {
            x,
            y,
            type: "scatter",
            mode: "markers",
            marker: {
                color: markerColors,
                size: COUNT_OF_MONTHS,
            },
            name: name ?? "",
            legendgroup: group ?? "markerGroup " + year,
            showlegend: false,
            opacity: isSecondary ? SECONDARY_LINE_OPACITY : 1,
        };

        const markersLegend: Plotly.Data = {
            x: [null],
            y: [null],
            type: "scatter",
            mode: "markers",
            marker: {
                color: GREEN_COLOR,
                size: COUNT_OF_MONTHS,
            },
            name: name ?? "",
            legendgroup: group ?? "markerGroup " + year,
            showlegend: !!name,
        };

        return { markersPlot, markersLegend };
    };

    const getLinesByYear = (
        year: number,
        isSecondary: boolean,
        preparedData: Record<number, DataPerYear>,
    ) => {
        const preparedDataByYear = preparedData?.[year];
        if (!preparedDataByYear) {
            return [];
        }

        const result = getProgressLine(
            preparedDataByYear.x,
            preparedDataByYear.yCalculated,
            isSecondary,
            `${t(k.PROGRESS)} ${t(k.OF)} ${year}`,
        );

        const belowOrEqualLine = getDashedLine(
            preparedDataByYear.x,
            preparedDataByYear.yTarget.map(
                (val) =>
                    (val * getCorrectedThresholds(val).underThreshold) / 100,
            ),
            overTargetIsGood ? RED_COLOR : GREEN_COLOR,
            isSecondary,
            `${t(k.TARGET)} * ${t(k.OVER_THRESHOLD)} ${t(k.OF)} ${year}`,
        );

        const { markersPlot, markersLegend } = getMarkers(
            preparedDataByYear,
            isSecondary,
            `${t(k.MARKERS_OF_PROGRESS_LINE)} ${t(k.OF)} ${year}`,
            year,
        );

        const aboveOrEqualLine = getDashedLine(
            preparedDataByYear.x,
            preparedDataByYear.yTarget.map(
                (val) =>
                    (val * getCorrectedThresholds(val).overThreshold) / 100,
            ),
            overTargetIsGood ? GREEN_COLOR : RED_COLOR,
            isSecondary,
            `${t(k.TARGET)} * ${t(k.UNDER_THRESHOLD)} ${t(k.OF)} ${year}`,
        );

        return [
            result,
            belowOrEqualLine,
            markersPlot,
            markersLegend,
            aboveOrEqualLine,
        ];
    };

    useEffect(() => {
        setPreparedData(castPreparedData(values));
    }, [values, overTargetIsGood]);

    const data = useMemo(() => {
        const lines = values.years.reduce<Plotly.Data[]>((acc, year, index) => {
            const lines = getLinesByYear(year, index !== 0, preparedData);
            acc.push(...lines);
            return acc;
        }, []);

        return lines;
    }, [values, preparedData]);

    const layout: Partial<Plotly.Layout> = {
        legend: { orientation: "h", tracegroupgap: 4 },
        hoverlabel: {
            align: "left",
            bgcolor: "#fff",
        },
        xaxis: {
            showgrid: false,
            zeroline: false,
            showline: true,
            mirror: "ticks",
            side: "top",
            tickfont: {
                size: COUNT_OF_MONTHS,
            },
        },
        yaxis: {
            showgrid: false,
            zeroline: false,
            showline: true,
            mirror: "ticks",
            tickfont: {
                size: COUNT_OF_MONTHS,
            },
        },
        dragmode: "pan",
    };

    const config = { displayModeBar: false, responsive: true };

    return (
        <React.Suspense fallback={<LazyLoadingFallback />}>
            <Plot data={data} layout={layout} config={config} />
        </React.Suspense>
    );
};

export default KpiChart;
