import "./DeleteAndUndoButton.scss";

import React from "react";

import IconButton from "common/components/buttons/icon-button/IconButton";
import { DeleteIcon, UndoIcon } from "common/components/icons";

interface IProps {
    testId?: string;
    isDeleted?: boolean;

    onClick: () => void;
}

const DeleteAndUndoButton: React.FC<React.PropsWithChildren<IProps>> = ({
    testId,
    isDeleted,
    onClick,
}) => {
    const handleDelete = (e: any) => {
        e.preventDefault();
        onClick();
    };
    return (
        <IconButton testId={testId} onClick={handleDelete}>
            {isDeleted ? <UndoIcon /> : <DeleteIcon />}
        </IconButton>
    );
};

export default DeleteAndUndoButton;
