import "./SidePanelConfigurationList.scss";

import { useContext } from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import ImprovementFormContext from "../../../context/ImprovementFormContext";
import { CONFIGURATION_STEP_ID } from "../../../context/ImprovementFormData";

const SidePanelConfigurationList: React.FC = (props) => {
    const { mobileView, currentSection, sectionRefs, scrollToTarget } =
        useContext(ImprovementFormContext);

    const { t } = useTranslation();

    const handleOnSelect = () => {
        if (sectionRefs && scrollToTarget) {
            scrollToTarget({ targetId: CONFIGURATION_STEP_ID, sectionRefs });
        }
    };

    return mobileView ? null : (
        <div
            className="improvement-form--side-panel-list"
            data-testid="template-step-list"
        >
            <div className="side-panel-list__steps-list">
                <div
                    className={`steps-list-item ${
                        CONFIGURATION_STEP_ID === currentSection
                            ? "selected"
                            : ""
                    }`}
                    onClick={handleOnSelect}
                >
                    <div className="steps-list-item--column">
                        <div className="steps-list-item--title">
                            {t(k.CONFIGURATION)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SidePanelConfigurationList;
