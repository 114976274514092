import React from "react";

import { ListGroup } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import {
    SortEnd,
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";

import { arrayMoveTo } from "../../../common/utils/arrayUtils";

const DragHandle = SortableHandle(() => (
    <div className="d-flex align-items-center mr-1">
        <FaBars />
    </div>
));

interface ISortableItemProps {
    className?: string;
    children?: React.ReactNode;
}

const SortableItem = SortableElement<ISortableItemProps>(
    (props: ISortableItemProps) => {
        return (
            <ListGroup.Item
                className={
                    props.className ? `d-flex ${props.className}` : "d-flex"
                }
            >
                <DragHandle />

                {props.children}
            </ListGroup.Item>
        );
    },
);

const SortableList = SortableContainer<{ children: React.ReactNode }>(
    (props: { children: React.ReactNode }) => (
        <ListGroup>{props.children}</ListGroup>
    ),
);

interface IProps<T> {
    items: T[];

    className?: string;

    render: (item: T, index: number) => React.ReactNode;
    onSorted?: (items: T[]) => void;
}

const SortableListWithHandle = <T extends { id: string }>(props: IProps<T>) => {
    const { items, className } = props;

    const onSortEnd = (params: SortEnd) => {
        if (props.onSorted) {
            const sortedItems = arrayMoveTo(
                [...items],
                params.oldIndex,
                params.newIndex,
            );

            props.onSorted(sortedItems);
        }
    };

    return (
        <SortableList
            onSortEnd={onSortEnd}
            useDragHandle
            useWindowAsScrollContainer
            helperClass="drag-and-drop--item"
        >
            {items.map((item, index) => {
                const itemContent = props.render(item, index);

                return (
                    <SortableItem
                        key={item.id}
                        index={index}
                        className={className}
                    >
                        {itemContent}
                    </SortableItem>
                );
            })}
        </SortableList>
    );
};

export default SortableListWithHandle;
