import "./EditableStepHeaderDetails.scss";

import React from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import k from "i18n/keys";

import IsBlockingStep from "../../../IsBlockingStep";
import {
    handleOnChangeStepUtil,
    handleOnChangeTeamsUtil,
} from "../../EditableStep.utils";
import { EActivityType } from "common/components/input-components/EActivityType";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import InputComponent from "common/components/input-components/InputComponent";
import IValidationMessages from "common/viewModels/IValidationMessages";
import ActionTextFieldWithState from "components/common/actions/ActionTextFieldWithState";
import { IInputActionChangeEvent } from "components/common/actions/IInputActionProps";
import InputActionTextField from "components/common/actions/inputActions/InputActionTextField";
import ActionTextField from "components/common/actions/inputActions/components/ActionTextField";
import TeamsMultiselectWithWrapper from "components/common/multiselect-dropdowns/TeamsMultiselectWithWrapper";
import { _improvementStepTitles } from "components/improvement-forms/components/ImprovementForm/utils";
import EquipmentMultiselectWithWrapper from "components/steps/dropdowns/EquipmentMultiselectWithWrapper";
import ITableSet from "http/ITableSet";
import { getLocaleString } from "i18n/components/GetLocaleString";
import IStep from "models/IStep";
import { IAppState } from "store/IAppState";

interface IProps {
    step: IStep;
    isArchived?: boolean;
    errors?: IValidationMessages;

    setSteps?: React.Dispatch<React.SetStateAction<ITableSet<IStep>>>;
    setErrors?: React.Dispatch<
        React.SetStateAction<IValidationMessages | undefined>
    >;

    onHaveChanges: (value: boolean) => void;
}

const EditableStepHeaderDetails = (props: React.PropsWithChildren<IProps>) => {
    const { step, isArchived, errors, onHaveChanges, setSteps, setErrors } =
        props;

    const { t } = useTranslation();
    const hasEquipmentFeatureEnabled = useSelector((appState: IAppState) => {
        return appState.authViewState.profile?.appFeatures?.equipment;
    });

    const isDisabled = step.isDeleted || isArchived || step.isLocal !== true;

    const handleOnChangeDescription = (e: IInputChangeEvent<string>) => {
        handleOnChangeStep({ ...step, description: e.value });
    };

    const handleOnChangeName = (e: IInputActionChangeEvent<string>) => {
        handleOnChangeStep({ ...step, name: e.value });
        onHaveChanges(true);
    };

    const handleOnChangeStep = (step: IStep) => {
        handleOnChangeStepUtil(step, setSteps);
        onHaveChanges(true);
    };

    const handleOnChangeTeams = (
        teamIds: string[],
        isTeamEveryoneSelected: boolean,
    ) => {
        handleOnChangeTeamsUtil(
            teamIds,
            isTeamEveryoneSelected,
            step,
            setSteps,
            setErrors,
        );
    };

    const handleOnChangeEquipments = (values: string[]) => {
        handleOnChangeStep({
            ...step,
            equipmentIds: values,
        });
    };

    const handleIsBockingStep = (value: boolean) => {
        handleOnChangeStep({ ...step, isBlocking: value });
    };

    return (
        <>
            {!step.isDeleted && (
                <div className="editable-step--header-details">
                    <ActionTextField
                        label={t(k.STEP_LABEL)}
                        boldLabel
                        id={`step.${step.id}.name`}
                        testId="step-title-input"
                        withDebounce
                        required
                        multiline
                        minRows={1}
                        inputSize="large"
                        disabled={isDisabled}
                        value={step.name ?? ""}
                        onChange={handleOnChangeName}
                        placeholder={t(k.NAME)}
                        error={getLocaleString(
                            t,
                            errors?.[`step.${step.id}.name`],
                        )}
                    />

                    <TeamsMultiselectWithWrapper
                        id={`step.${step.id}.teams`}
                        testId="teams-multiselect"
                        values={step.teamIds}
                        isEveryoneSelected={step.isTeamEveryone}
                        isDisabled={isDisabled}
                        required
                        error={getLocaleString(
                            t,
                            errors?.[`step.${step.id}.teams`],
                        )}
                        onChange={handleOnChangeTeams}
                    />

                    {hasEquipmentFeatureEnabled && (
                        <EquipmentMultiselectWithWrapper
                            testId="equipment-multiselect"
                            values={step.equipmentIds}
                            isDisabled={isDisabled}
                            onChange={handleOnChangeEquipments}
                        />
                    )}

                    <InputComponent
                        testId="description-input"
                        inputType={EActivityType.MultiLineTextfield}
                        wrapperLabel={t(k.DESCRIPTION)}
                        hideIcon
                        boldLabel
                        id={step.id}
                        value={step.description ?? ""}
                        placeholder={t(k.WRITE_A_DESCRIPTION_FOR_THIS_STEP)}
                        disabled={isDisabled}
                        onChange={handleOnChangeDescription}
                    />

                    <IsBlockingStep
                        testId="is-blocking-step"
                        value={step.isBlocking}
                        isDisabled={isDisabled}
                        onClick={handleIsBockingStep}
                    />
                </div>
            )}
        </>
    );
};

export default EditableStepHeaderDetails;
