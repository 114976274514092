import "./ActivityCheckField.scss";

import React from "react";

import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { FaBars } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import {
    SortEnd,
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";

import { v4 as uuidv4 } from "uuid";

import k from "i18n/keys";

import ActivityDetails from "./ActivityDetails";
import { IActivityProps } from "./IActivityProps";
import Button from "common/components/buttons/Button";
import { IMenuOption } from "common/components/buttons/menu-button/IMenuOption";
import MenuButton from "common/components/buttons/menu-button/MenuButton";
import {
    MeatballsIcon,
    RequiredStarIcon,
    TaskCheckboxIcon,
} from "common/components/icons";
import { EActivityType } from "common/components/input-components/EActivityType";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import InputComponent from "common/components/input-components/InputComponent";
import Checkbox from "common/components/input-components/checkbox/Checkbox";
import Tooltip from "common/components/tooltip/Tooltip";
import { arrayMoveTo } from "common/utils/arrayUtils";
import IActivityInputListItem from "http/requests/IActivityInputListItem";
import { getLocaleString } from "i18n/components/GetLocaleString";

const DragHandle = SortableHandle(() => (
    <div className="step-activity-sortable-handle">
        <FaBars />
    </div>
));

interface ISortableItemProps extends Pick<IActivityProps, "errors"> {
    value: IActivityInputListItem;
    order: number;
    isEditMode?: boolean;

    onDelete: () => void;
    onChange: (e: IInputChangeEvent<string>) => void;
    onIsRequiredChange: (e: IInputChangeEvent<boolean>) => void;
}

const SortableItem = SortableElement<ISortableItemProps>(
    (props: ISortableItemProps) => {
        const {
            value,
            order,
            onChange,
            errors,
            onIsRequiredChange,
            onDelete,
            isEditMode,
        } = props;

        const { t } = useTranslation();
        const hasErrors = Boolean(
            errors ? errors[`ActivityListItem.${value.id}.Label`] : false,
        );

        const handleOnMenuSelect = (option: string) => {
            switch (option) {
                case "required":
                    onIsRequiredChange({
                        name: value.id,
                        value: !value.isRequired,
                    });
                    break;
                case "delete":
                    onDelete();
                    break;
            }
        };

        const menuOptions: IMenuOption[] = [
            {
                id: "required",
                label: t(k.REQUIRED),
                selected: value.isRequired,
                testId: "menu-button-required",
            },
            { id: "delete", label: t(k.DELETE), testId: "menu-button-delete" },
        ];

        return (
            <div
                className="step-activity-tasklist--item"
                data-testid={`tasklist-item-${order}`}
            >
                <div className="step-activity-tasklist--container">
                    {isEditMode ? (
                        <InputComponent
                            testId="tasklist-input"
                            wrapperInputTestId="tasklist-input-wrapper"
                            inputType={EActivityType.MultiLineTextfield}
                            size="large"
                            name={value.id}
                            wrapperIcon={<DragHandle />}
                            noIconResize
                            hideIcon
                            id={`ActivityListItem.${value.id}.Label`}
                            value={value.label}
                            placeholder={t(k.LABEL)}
                            onChange={onChange}
                            invalid={hasErrors}
                            preview={!isEditMode}
                            minRows={1}
                            errors={
                                errors?.[`ActivityListItem.${value.id}.Label`]
                            }
                            preInputContent={
                                value.isRequired ? (
                                    <Tooltip
                                        wrapperClassName="required-tooltip-wrapper"
                                        id="required-tooltip"
                                        message={t(k.MARKED_AS_REQUIRED)}
                                    >
                                        <RequiredStarIcon />
                                    </Tooltip>
                                ) : null
                            }
                            afterInput={
                                <MenuButton
                                    testId="task-item-menu"
                                    icon={<MeatballsIcon />}
                                    options={menuOptions}
                                    showCheckbox
                                    onSelect={handleOnMenuSelect}
                                />
                            }
                        />
                    ) : (
                        <div className="tasklist-preview">
                            <Checkbox
                                bold
                                id={value.id}
                                label={
                                    value.label.length === 0
                                        ? t(k.LABEL)
                                        : value.label
                                }
                                value={false}
                                disabled
                            />
                            <ActivityDetails
                                isRequired={value.isRequired}
                                hasError={hasErrors}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    },
);

const SortableList = SortableContainer<{ children: React.ReactNode }>(
    (props: { children: React.ReactNode }) => {
        return <div className="step-activity-tasklist">{props.children}</div>;
    },
);

const ActivityCheckField: React.FC<IActivityProps> = (props) => {
    const {
        activity,
        activityList = [],
        onChangeList,
        typeField,
        isEditMode,
        noTasksMessage,
    } = props;

    const { t } = useTranslation();

    const handleAddListItem = () => {
        const newActivity: IActivityInputListItem = {
            id: uuidv4(),
            label: "",
            isRequired: false,
        };

        onChangeList?.(activity.id, [...(activityList ?? []), newActivity]);
    };

    const handleChangeIsRequired = (e: IInputChangeEvent<boolean>) => {
        onChangeList?.(
            activity.id,
            activityList.map((item) =>
                item.id === e.name ? { ...item, isRequired: e.value } : item,
            ),
        );
    };

    const handleChangeListItem = (e: IInputChangeEvent<string>) => {
        onChangeList?.(
            activity.id,
            activityList.map((item) =>
                item.id === e.name ? { ...item, label: e.value } : item,
            ),
        );
    };

    const handleDelete = (id: string) => () => {
        onChangeList?.(
            activity.id,
            activityList.filter((item) => item.id !== id),
        );
    };

    const onSortEndHandler = (params: SortEnd) => {
        const sortedItems = arrayMoveTo(
            activityList,
            params.oldIndex,
            params.newIndex,
        );
        onChangeList?.(activity.id, sortedItems);
    };

    const typeList: React.ReactElement = (
        <div className="step-activity-options">
            {isEditMode && (
                <div className="step-activity-options--field">
                    <div className="icon">
                        <TaskCheckboxIcon />
                    </div>
                    <div className="step-activity-options--label">
                        {t(k.TASKS)}
                    </div>
                </div>
            )}
            {activityList.length > 0 ? (
                <SortableList
                    lockAxis="y"
                    useDragHandle
                    useWindowAsScrollContainer
                    helperClass="drag-and-drop--item"
                    onSortEnd={onSortEndHandler}
                >
                    {activityList.map((value, index) => (
                        <SortableItem
                            isEditMode={isEditMode}
                            key={value.id}
                            index={index}
                            order={index}
                            value={value}
                            errors={props.errors}
                            onChange={handleChangeListItem}
                            onIsRequiredChange={handleChangeIsRequired}
                            onDelete={handleDelete(value.id)}
                        />
                    ))}
                </SortableList>
            ) : (
                <p className="step-activity-tasklist--empty">
                    {getLocaleString(
                        t,
                        noTasksMessage ??
                            k.NO_TEMPLATE_LEVEL_TASKS_ARE_ADDED_FOR_THE_ACTIVITY_USERS_WILL_BE_ABLE_TO_ADD_THEIR_OWN_TASKS_IN_RUNNING_CHECKLISTS,
                    )}
                </p>
            )}

            {isEditMode && (
                <div className="step-activity-options--button">
                    <Button
                        transparent
                        size="small"
                        onClick={handleAddListItem}
                        icon={<FiPlus size="22" />}
                    >
                        {t(k.ADD_TASK)}
                    </Button>
                </div>
            )}
        </div>
    );

    return typeField({
        typeList,
        typeLabel: `${t(k.LABEL)} ${t(k.FOR_)} ${t(k.ADD_CHECKBOX)}`,
    });
};

export default ActivityCheckField;
