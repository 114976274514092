import { Context, PropsWithChildren, useContext } from "react";

import StepInstanceListItem from "../StepInstanceListItem";
import StepListItem from "../StepListItem";
import ICurrentSectionData from "common/components/current-scrolled-section/models/ICurrentSectionData";
import IStepItemListing from "common/components/listed-content/models/IStepItemListing";

export enum EParentType {
    checklist,
    improvement,
    template,
}

interface IProps<T extends IStepItemListing & ICurrentSectionData> {
    context: Context<T>;
    parentType?: EParentType;
}

const ListItems = <T extends IStepItemListing & ICurrentSectionData>(
    props: PropsWithChildren<IProps<T>>,
) => {
    const {
        stepInstances,
        steps,
        sectionRefs,
        scrollToTarget,
        currentSection,
    } = useContext(props.context);

    const handleOnSelect = (id: string) => {
        if (sectionRefs && scrollToTarget) {
            scrollToTarget({ targetId: id, sectionRefs });
        }
    };

    const isSelected = (id: string) => {
        let dataIdFromStepWrapper = "step." + id + "name";

        return (
            dataIdFromStepWrapper === currentSection || id === currentSection
        );
    };

    return (
        <div className="side-panel__list__steps-list">
            {stepInstances?.ids.map((x, index) => (
                <StepInstanceListItem
                    key={x}
                    stepInstance={stepInstances.values[x]}
                    index={index + 1}
                    selected={isSelected(x)}
                    onSelected={handleOnSelect}
                    parentType={props.parentType}
                />
            ))}
            {steps?.ids.map((x, index) => (
                <StepListItem
                    key={x}
                    step={steps.values[x]}
                    index={index + 1}
                    selected={isSelected(x)}
                    onSelected={handleOnSelect}
                />
            ))}
        </div>
    );
};

export default ListItems;
