import "./ValueWrapper.scss";

import React, { PropsWithChildren } from "react";

import { useTranslation } from "react-i18next";

import classnames from "classnames";

import k from "i18n/keys";

import RemoveButton from "../buttons/remove-button/RemoveButton";

interface IValueWrapperProps {
    id?: string;

    className?: string;

    isArchived?: boolean;
    isDeleted?: boolean;
    customColor?: string;
    customBackgroundColor?: string;
    customBorderColor?: string;

    ellipsis?: boolean;

    children?: React.ReactNode;
    testId?: string;

    onClick?: (id?: string) => void;
    onRemove?: (id?: string) => void;
}

const ValueWrapper = (props: IValueWrapperProps) => {
    const {
        id,
        isArchived,
        isDeleted,
        ellipsis,
        children,
        testId,
        customColor,
        customBackgroundColor,
        customBorderColor,
    } = props;

    const { t } = useTranslation();

    const className = classnames("value-wrapper", {
        "value-wrapper--link": !!props.onClick,
        "value-wrapper--deleted": isDeleted,
        "value-wrapper--archived": isArchived,
        "value-wrapper--ellipsis": ellipsis,
    });

    const handleClick = (e: React.UIEvent) => {
        e.stopPropagation();

        if (props.onClick) {
            props.onClick(id);
        }
    };

    const handleOnRemove = (e: React.UIEvent, id?: string) => {
        props.onRemove?.(id);
    };

    const specialTitle = isDeleted
        ? t(k.DELETED)
        : isArchived
          ? t(k.ARCHIVED)
          : undefined;

    const finalTitle = specialTitle
        ? specialTitle + (specialTitle && children ? " | " + children : "")
        : children;

    return (
        <div
            className={className}
            style={{
                backgroundColor: customBackgroundColor,
                color: customColor,
                borderColor: customBorderColor,
            }}
            data-testid={testId}
            title={typeof finalTitle == "string" ? finalTitle : undefined}
            onClick={props.onClick ? handleClick : undefined}
        >
            <div className="value-wrapper--label">{children}</div>
            {props.onRemove && (
                <RemoveButton id={id} onClick={handleOnRemove} />
            )}
        </div>
    );
};

export default ValueWrapper;

interface ICheckboxValueWrapperProps {
    className?: string;

    checked?: boolean;

    ellipsis?: boolean;

    children?: string;
    testId?: string;
}

// Temp. Should be replaced by its own component with its own design
export const CheckboxValueWrapper = (props: ICheckboxValueWrapperProps) => {
    const { checked, ellipsis, children, testId } = props;

    const className = classnames("value-wrapper", {
        "value-wrapper--ellipsis": ellipsis,
    });

    return (
        <div className={className} data-testid={testId} title={children}>
            <input
                className="value-wrapper--checkbox"
                type="checkbox"
                checked={checked}
                readOnly={true}
            />

            <div className="value-wrapper--label">{children}</div>
        </div>
    );
};
