import "./Content.scss";

import React, { useContext } from "react";

import { useSelector } from "react-redux";

import ImprovementFormContext from "../../context/ImprovementFormContext";
import ConfigurationStep from "./components/ConfigurationStep/ConfigurationStep";
import CompleteStep from "common/components/complete-step/CompleteStep";
import { EParentType } from "common/components/listed-content/components/SidePanel/List/components/ListItems/ListItems";
import RegistrationStep from "components/improvements/components/RegistrationStep/RegistrationStep";
import EditableStep from "components/steps/components/editable-step/EditableStep";
import { IAppState } from "store/IAppState";

const Content: React.FC = (props) => {
    const { steps, sectionRefs, errors, onHaveChanges, isArchived } =
        useContext(ImprovementFormContext);

    const impactedProcessesFeature = useSelector(
        (appState: IAppState) =>
            (appState.authViewState.profile?.appFeatures?.processChart &&
                appState.authViewState.profile?.appFeatures
                    ?.assignImpactedProcessesToImprovement) === true,
    );

    return (
        <>
            {impactedProcessesFeature && <ConfigurationStep />}
            {steps &&
                sectionRefs &&
                steps.ids.map((id, index) => {
                    const step = steps.values[id];
                    const stepRef = sectionRefs[id];

                    if (step && stepRef) {
                        return (
                            <EditableStep
                                key={id}
                                stepRef={stepRef}
                                index={index}
                                lastIndex={steps.ids.length - 1}
                                step={step}
                                isArchived={isArchived}
                                errors={errors}
                                parentType={EParentType.improvement}
                                headerDetailsContent={
                                    index === 0 && (
                                        <RegistrationStep editable={false} />
                                    )
                                }
                                footerContent={
                                    index === 3 ? (
                                        <CompleteStep
                                            preview
                                            name={step.name}
                                        />
                                    ) : (
                                        <CompleteStep
                                            disabled
                                            name={step.name}
                                        />
                                    )
                                }
                                onHaveChanges={onHaveChanges}
                            />
                        );
                    }
                })}
        </>
    );
};

export default Content;
