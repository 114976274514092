import { ReactElement, RefObject, useEffect, useRef, useState } from "react";

import IDictionary from "common/viewModels/IDictionary";
import throttle from "lodash/throttle";

/* Don't forget to add data-id to the element in the scrollable object */
interface ICurrentScrolledSection {
    contentRef?: RefObject<HTMLDivElement>;
    wrapperRef?: RefObject<HTMLDivElement>;
    stickySelector?: string;
    initialSection?: string;

    scrollOffset?: number;
    mobileView: boolean;
    children: (
        scrollToTarget: (props: IScrollToTarget) => void,
        currentSection?: string,
    ) => ReactElement;
}

const CurrentScrolledSection = ({
    contentRef,
    wrapperRef = contentRef,
    stickySelector = ".side-panel--sticky",
    initialSection,
    scrollOffset = 70,
    mobileView,
    children,
}: ICurrentScrolledSection) => {
    const [scrollRefsLoaded, setScrollRefsLoaded] = useState(false);

    const [currentSection, setCurrentSection] = useState<string | undefined>(
        initialSection,
    );

    const stickyTopOffsetRef = useRef(0);

    useEffect(() => {
        if (!scrollRefsLoaded && wrapperRef?.current && contentRef?.current) {
            setScrollRefsLoaded(true);
        }
    });

    const scrollRef = mobileView ? wrapperRef : contentRef;

    useEffect(() => {
        const updateStickyTopOffset = throttle(() => {
            const scrollElement = scrollRef?.current;

            if (scrollElement && stickySelector) {
                const stickyElements =
                    scrollElement.querySelectorAll(stickySelector);

                if (stickyElements) {
                    let height = 0;

                    stickyElements.forEach((element) => {
                        height += element.clientHeight;
                    });

                    stickyTopOffsetRef.current = height;
                }
            }
        }, 250);

        if (mobileView) {
            updateStickyTopOffset();

            window.addEventListener("resize", updateStickyTopOffset);
        } else {
            stickyTopOffsetRef.current = 0;

            updateStickyTopOffset.cancel();
        }

        return () => {
            window.removeEventListener("resize", updateStickyTopOffset);
            stickyTopOffsetRef.current = 0;
            updateStickyTopOffset.cancel();
        };
    }, [stickySelector, scrollRefsLoaded, mobileView]);

    useEffect(() => {
        const spyScrollCurrentSection = (scrollTarget: HTMLDivElement) => {
            if (contentRef?.current) {
                setCurrentSection(
                    spyScroll({
                        scrollTarget,
                        contentRef: contentRef.current,
                        scrollOffset: scrollOffset + stickyTopOffsetRef.current,
                    }),
                );
            }
        };

        const throttledOnScroll = throttle(
            (e) => spyScrollCurrentSection(e.target),
            100,
        );

        const scrollElement = scrollRef?.current;

        if (scrollRefsLoaded && scrollElement) {
            spyScrollCurrentSection(scrollElement);

            scrollElement.addEventListener("scroll", throttledOnScroll);
        }

        return () => {
            scrollElement?.removeEventListener("scroll", throttledOnScroll);
        };
    }, [scrollRefsLoaded, stickyTopOffsetRef, scrollOffset, mobileView]);

    useEffect(() => {
        if (currentSection === undefined) {
            setCurrentSection(initialSection);
        }
    }, [initialSection]);

    return children(
        scrollToTargetWrapper({
            scrollRef: mobileView ? wrapperRef : contentRef,
            offset: (mobileView ? stickyTopOffsetRef.current : 0) + 5,
        }),
        currentSection,
    );
};

const spyScroll = ({
    scrollTarget,
    contentRef,
    scrollOffset,
}: {
    scrollTarget?: HTMLDivElement;
    contentRef?: HTMLDivElement;
    scrollOffset: number;
}) => {
    if (!(scrollTarget && contentRef)) return undefined;

    const contentOffsetTop = contentRef.offsetTop;

    const children = [...contentRef.children] as HTMLElement[];

    const targetElements: IDictionary<HTMLElement> = children.reduce(
        (map, item) =>
            item.dataset.id ? { ...map, [item.dataset.id]: item } : map,
        {},
    );

    let bestMatch: { sectionName?: string; delta?: number } = {};

    for (const sectionName in targetElements) {
        if (Object.prototype.hasOwnProperty.call(targetElements, sectionName)) {
            const domElm = targetElements[sectionName];

            const scroll = scrollTarget.scrollTop - contentOffsetTop;
            const delta = domElm.offsetTop - scroll;

            if (!bestMatch.sectionName) {
                bestMatch = { sectionName, delta };
            } else if (
                bestMatch.delta &&
                delta <= scrollOffset &&
                (delta > bestMatch.delta ||
                    (bestMatch.delta > scrollOffset && delta < bestMatch.delta))
            ) {
                bestMatch = { sectionName, delta };
            }
        }
    }

    return bestMatch.sectionName;
};

interface IScrollToTargetWrapper {
    scrollRef?: RefObject<HTMLDivElement>;
    offset?: number;
}

export interface IScrollToTarget {
    targetId: string;
    sectionRefs: IDictionary<RefObject<HTMLDivElement>>;
    timeout?: number;
    behavior?: ScrollBehavior;
}

export const scrollToTargetWrapper =
    ({ scrollRef, offset = 0 }: IScrollToTargetWrapper) =>
    (props: IScrollToTarget) => {
        const {
            targetId,
            sectionRefs,
            timeout = 100,
            behavior = "smooth",
        } = props;

        const scrollParent = scrollRef?.current;

        const target = sectionRefs[targetId]?.current;

        if (target && scrollParent) {
            let targetTop = target.offsetTop;

            let relativeParent = target?.parentElement;

            while (
                relativeParent &&
                relativeParent !== scrollParent &&
                relativeParent !== document.body
            ) {
                if (
                    window.getComputedStyle(relativeParent).position ===
                    "relative"
                ) {
                    targetTop = targetTop + relativeParent.offsetTop;
                }

                relativeParent = relativeParent.parentElement;
            }

            setTimeout(() => {
                scrollParent.scrollTo({
                    behavior,
                    top: targetTop - offset,
                });
            }, timeout);
        }
    };

export default CurrentScrolledSection;
