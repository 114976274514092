import "./DropdownTreeMenu.scss";

import { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import _ from "lodash";

import k from "i18n/keys";

import { IInputChangeEvent } from "../../IInputProps";
import Checkbox from "../../checkbox/Checkbox";
import { IDropdownTreeData } from "./IDropdownTreeData";
import AccordionLeft from "common/components/accordion/AccordionLeft";
import { Button } from "common/components/buttons";
import { SearchIcon } from "common/components/icons";
import TextHighlightWrapper from "common/components/text-highlight-wrapper/TextHighlightWrapper";
import { IInputActionChangeEvent } from "components/common/actions/IInputActionProps";
import ActionTextField from "components/common/actions/inputActions/components/ActionTextField";
import { ITableSetWithOptions } from "http/ITableSetWithOptions";

export interface IDropdownTreeMenuProps {
    data: ITableSetWithOptions<IDropdownTreeData, string>;
    showMenu?: boolean;
    showSearch?: boolean;
    useApply?: boolean;

    onChange?: (ids: string[]) => void;
}

function DropdownTreeMenu({
    data,
    showMenu,
    showSearch,
    useApply,
    onChange,
}: IDropdownTreeMenuProps) {
    const { t } = useTranslation();

    const selectedIds = useRef<string[]>([]);

    const [treeData, setTreeData] =
        useState<ITableSetWithOptions<IDropdownTreeData, string>>(data);

    const [searchValue, setSearchValue] = useState<string>("");

    useEffect(() => {
        if (data && showMenu) {
            setTreeData(updateTreeData(data, searchValue));
        } else if (data) {
            setSearchValue("");
            setTreeData(updateTreeData(data, ""));
        }
    }, [data, searchValue, showMenu]);

    const updateTreeData = (
        data: ITableSetWithOptions<IDropdownTreeData, string>,
        searchValue?: string,
    ) => {
        const tempValues = _.cloneDeep(data.values);

        const updateTreeNode = (node: IDropdownTreeData) => {
            let isPartiallyChecked = false;
            let childMatch = false;

            const match = !!searchValue
                ? node.label.toLowerCase().includes(searchValue.toLowerCase())
                : false;

            if (node.childIds && node.childIds.length > 0) {
                const children = node.childIds.reduce<IDropdownTreeData[]>(
                    (acc, childId) => {
                        const child = tempValues[childId];

                        if (child) {
                            updateTreeNode(child);

                            const updatedChild = tempValues[childId];
                            if (updatedChild) {
                                acc.push(updatedChild);
                            }
                        }

                        return acc;
                    },
                    [],
                );

                isPartiallyChecked = children.some(
                    (child) => child?.checked || child?.partially,
                );

                childMatch = children.some(
                    (child) => child?.match || child?.childMatch,
                );
            }

            const updatedValue: IDropdownTreeData = {
                ...node,
                partially: isPartiallyChecked,
                expanded: node.expanded || isPartiallyChecked || childMatch,
                childMatch,
                match,
            };

            tempValues[node.value] = updatedValue;

            return updatedValue;
        };

        data.ids.forEach((valueId) => {
            const value = data.values[valueId];
            if (value) {
                tempValues[valueId] = updateTreeNode(value);
            }
        });

        selectedIds.current = Object.values(tempValues).reduce<string[]>(
            (acc, value) => {
                if (value?.checked) {
                    acc.push(value.value);
                }
                return acc;
            },
            [],
        );

        return {
            ...data,
            values: tempValues,
        };
    };

    const updateTreeDataApply = (changedId: string) => {
        setTreeData((prev) => {
            const tempValues = _.cloneDeep(prev.values);

            const updateTreeNode = (
                node: IDropdownTreeData,
                parentChecked?: boolean,
            ) => {
                let isPartiallyChecked = false;

                const isChanged = node.value === changedId;

                const prevChecked = node.checked;

                let newChecked = isChanged
                    ? !prevChecked
                    : (parentChecked ?? prevChecked);

                const childIds = node.childIds;
                if (childIds && childIds.length > 0) {
                    childIds.forEach((childId) => {
                        const child = tempValues[childId];

                        if (child) {
                            updateTreeNode(
                                child,
                                isChanged ? newChecked : undefined,
                            );
                        }
                    });

                    isPartiallyChecked = childIds.some((childId) => {
                        const child = tempValues[childId];
                        return child?.checked || child?.partially;
                    });

                    newChecked = isChanged
                        ? newChecked
                        : childIds.every((childId) => {
                              const child = tempValues[childId];
                              return child?.checked;
                          });
                }

                const updatedValue = {
                    ...node,
                    partially: isPartiallyChecked,
                    checked: newChecked,
                    expanded: node.expanded || isPartiallyChecked,
                };

                tempValues[node.value] = updatedValue;
            };

            prev.options.forEach((valueId) => {
                const value = tempValues[valueId];
                if (value) {
                    updateTreeNode(value);
                }
            });

            selectedIds.current = Object.values(tempValues).reduce<string[]>(
                (acc, value) => {
                    if (value?.checked) {
                        acc.push(value.value);
                    }
                    return acc;
                },
                [],
            );

            return {
                ...prev,
                values: tempValues,
            };
        });
    };

    const handleOnSelect = (e: IInputChangeEvent<boolean, any>) => {
        const id = e.id;

        if (id && e.value !== undefined) {
            if (useApply) {
                updateTreeDataApply(id);
            } else {
                const newIds = selectedIds.current.includes(id)
                    ? selectedIds.current.filter((i) => i !== id)
                    : [...selectedIds.current, id];

                onChange?.(newIds);
            }
        }
    };

    const handleOnApply = () => {
        onChange?.(selectedIds.current);
        selectedIds.current = [];
    };

    const handleOnSearch = (e: IInputActionChangeEvent<string>) => {
        setSearchValue(e.value);
    };

    const renderTree = (optionsIds: string[] | undefined) => {
        return optionsIds?.map((id) => {
            const option = treeData.values[id];

            if (
                !option ||
                (!!searchValue && !option.match && !option.childMatch)
            ) {
                return null;
            }

            return (
                <div
                    key={option.value as string}
                    className="dropdown-tree-menu__item"
                >
                    {option.childIds && option.childIds.length > 0 ? (
                        <AccordionLeft
                            open={option.expanded}
                            listenOpen
                            header={
                                <Checkbox
                                    id={option.value}
                                    onChange={handleOnSelect}
                                    value={option.checked}
                                    label={option.label}
                                    partial={
                                        !option.checked && option.partially
                                    }
                                />
                            }
                        >
                            <div className="dropdown-tree-menu__children">
                                {renderTree(option.childIds)}
                            </div>
                        </AccordionLeft>
                    ) : (
                        <>
                            <div className="dropdown-tree-menu__fake-accordion" />

                            <Checkbox
                                id={option.value}
                                onChange={handleOnSelect}
                                value={option.checked}
                                label={
                                    <TextHighlightWrapper
                                        text={option.label}
                                        textToReplace={searchValue}
                                        ignoreCase
                                    />
                                }
                            />
                        </>
                    )}
                </div>
            );
        });
    };

    return (
        showMenu && (
            <div className="dropdown-tree-menu">
                {showSearch && (
                    <ActionTextField
                        id="dropDownTreeMenu"
                        postInputContent={
                            <div className="search-post-input-content">
                                <SearchIcon />
                            </div>
                        }
                        value={searchValue}
                        testId="kpi-details-form--name"
                        placeholder={t(k.SEARCH)}
                        autoFocus
                        onChange={handleOnSearch}
                    />
                )}
                {renderTree(treeData.options)}
                {useApply && (
                    <Button
                        className="dropdown-tree-menu__apply-btn"
                        variant="blue"
                        transparent
                        disabled={treeData.options.length === 0}
                        // testId={testId ? `${testId}-ApplyBtn` : undefined}
                        onClick={handleOnApply}
                    >
                        {selectedIds.current.length > 0 ? (
                            <>
                                {t(k.APPLY)} ({selectedIds.current.length})
                            </>
                        ) : (
                            t(k.APPLY)
                        )}
                    </Button>
                )}
            </div>
        )
    );
}

export default DropdownTreeMenu;
