import React, { useContext, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { AxiosError } from "axios";

import k from "i18n/keys";

import { markCompletedActivityInstanceAsync } from "../activity-instance-card/api/mutations";
import CompleteStep from "../complete-step/CompleteStep";
import StepInstanceCard from "./StepInstanceCard";
import { useMutation } from "@tanstack/react-query";
import { IValueLabelItem } from "common/IValueLabelItem";
import ActivityInstanceSetCardById from "common/components/activity-instance-card/ActivityInstanceSetCardById";
import { CommentsByStepInstanceId } from "common/components/comments";
import EquipmentsList from "common/components/equipment/EquipmentsList";
import StepInstanceFinalizeCardById from "common/components/step-instance-finalize-card/redux/StepInstanceFinalizeCardById";
import TeamsListEditable from "common/components/team-list/TeamsListEditable";
import IValidationMessages from "common/viewModels/IValidationMessages";
import ChecklistDetailsContext from "components/checklists/components/ChecklistListedContent/checklistContext";
import ImprovementsDetailsContext from "components/improvements/components/ImprovementListedContent/improvementContext";
import IChecklistActivityMarkCompleted from "http/requests/IChecklistActivityMarkCompleted";
import IStepInstance from "models/IStepInstance";
import { ChecklistDynamicStatus } from "models/enums/ChecklistDynamicStatus";
import { IShowConfirmArgs, showConfirmNoThunk } from "store/confirms/actions";
import { tryFinalize } from "store/stepInstances/actions";

export interface IChangeTypes {
    team?: IChangeTeam;
}

export interface IChangeTeam {
    team: IValueLabelItem<string, string>;
    currentIds: string[];
    remove?: boolean;
}

export interface IChangeEquipment {
    id: string;
    remove?: boolean;
}

interface IProps {
    id: string;
    orderIndex?: number;

    preview?: boolean;

    disableComments?: boolean;
    disableFinalize?: boolean;
    disableTeams?: boolean;
    isImprovement?: boolean;
    registrationStep?: JSX.Element;

    onChange?: <T extends keyof IChangeTypes>(
        stepId: string,
        type: T,
        data: IChangeTypes[T],
    ) => void;
}

const StepInstanceCardById = (props: IProps) => {
    const {
        id,
        orderIndex,

        preview,

        disableComments,
        disableTeams,
        isImprovement,
        registrationStep: registerStep,
    } = props;

    const {
        stepInstances,
        checklist,
        activityInstanceSetIdsByStepInstance,
        activityInstanceIdsBySet,
        activityInstances,
        editedSteps,
        editMode,
        sectionRefs,
        setErrors,
        refetchActivities,
    } = isImprovement
        ? useContext(ImprovementsDetailsContext)
        : useContext(ChecklistDetailsContext);

    const markCompletedMutation = useMutation({
        mutationFn: markCompletedActivityInstanceAsync,

        onSuccess: () => {
            refetchActivities?.();
        },
        onError: (
            error: AxiosError<{ errors: IValidationMessages }>,
            variables,
        ) => {
            if (
                error.response?.data &&
                typeof error.response.data === "object"
            ) {
                setErrors((prev) => ({
                    ...prev,
                    [variables.id]: error.response?.data.errors._error,
                }));
            } else {
                setErrors((prev) => ({
                    ...prev,
                    [variables.id]: String(error.response?.data),
                }));
            }
        },
    });

    const [invalidByActivityInstanceId, setInvalidByActivityInstanceId] =
        useState<Record<string, boolean | undefined>>({});

    const handleInvalidActivityInstanceIds = (invalidIds: string[]) => {
        setInvalidByActivityInstanceId(
            invalidIds.reduce<typeof invalidByActivityInstanceId>(
                (acc, cur) => {
                    acc[cur] = true;

                    return acc;
                },
                {},
            ),
        );

        setErrors((prev) => {
            if (prev) {
                invalidIds.forEach((id) => {
                    const activityInstance = activityInstances?.values[id];

                    if (activityInstance) {
                        delete prev[activityInstance.activityInstanceSetId];
                    }
                });
            }

            return prev;
        });
    };

    const dispatch = useDispatch<any>();

    const { t } = useTranslation();

    const stepInstance = {
        ...(stepInstances && stepInstances.values[id]),
        ...(editedSteps && editedSteps[id]),
    } as IStepInstance;

    const handleConfirmFinalize = async () => {
        const args: IShowConfirmArgs = {
            message: (
                <React.Fragment>
                    {t(k.DO_YOU_WANT_TO_FINALIZE_THIS_STEP)} <br />
                    {t(k.MOVE_ON_TO_THE_NEXT_STEP)}
                </React.Fragment>
            ),
            title: t(k.CONFIRM_FINALIZE),
            yesLabel: t(k.FINALIZE),
        };

        if (await showConfirmNoThunk(dispatch, args)) {
            dispatch(tryFinalize(id));
        }
    };

    const handleMarkCompleted = (request: IChecklistActivityMarkCompleted) => {
        markCompletedMutation.mutate(request);
    };

    const OnChangeTeam = (
        team: IValueLabelItem<string, string>,
        remove?: boolean,
    ) => {
        if (props.onChange && stepInstance) {
            props.onChange(stepInstance.id, "team", {
                team,
                currentIds: stepInstance.teamIds ?? [],
                remove,
            });
        }
    };

    if (!stepInstance || !activityInstanceSetIdsByStepInstance) {
        return null;
    }

    const showTeams =
        !disableTeams &&
        (stepInstance.teamIds?.length || stepInstance.isTeamEveryone);

    return (
        <StepInstanceCard
            stepInstance={stepInstance}
            orderIndex={orderIndex}
            isImprovement={isImprovement}
            activityInstanceList={
                <>
                    {registerStep}
                    {activityInstanceSetIdsByStepInstance[id] &&
                        activityInstanceSetIdsByStepInstance[id].map(
                            (activityInstanceSetId, index) => (
                                <ActivityInstanceSetCardById
                                    key={activityInstanceSetId}
                                    id={activityInstanceSetId}
                                    orderIndex={index + 1}
                                    preview={preview}
                                    availableUsers={stepInstance.availableUsers}
                                    onConfirmFinalize={handleConfirmFinalize}
                                    onMarkCompleted={handleMarkCompleted}
                                    isImprovement={isImprovement}
                                    isMarkCompletedBusy={
                                        activityInstanceSetId ===
                                        markCompletedMutation.variables?.id
                                            ? markCompletedMutation.isPending
                                            : false
                                    }
                                    invalidByActivityInstanceId={
                                        invalidByActivityInstanceId
                                    }
                                />
                            ),
                        )}
                </>
            }
            teamList={
                showTeams && (
                    <TeamsListEditable
                        isTeamEveryone={stepInstance.isTeamEveryone}
                        teamIds={stepInstance.teamIds}
                        editMode={editMode}
                        onChange={OnChangeTeam}
                    />
                )
            }
            equipmentList={
                !!stepInstance.equipmentIds?.length && (
                    <EquipmentsList equipmentIds={stepInstance.equipmentIds} />
                )
            }
            finalizeCard={
                isImprovement ? (
                    <>
                        <CompleteStep
                            stepInstanceId={stepInstance.id}
                            name={stepInstance.name}
                        />
                    </>
                ) : (
                    <StepInstanceFinalizeCardById
                        stepInstance={stepInstance}
                        checklistStatus={
                            checklist?.status ?? ChecklistDynamicStatus.None
                        }
                        activityInstanceSetIdsByStepInstanceId={
                            activityInstanceSetIdsByStepInstance[id]
                        }
                        activityInstancesIdsByActivityInstanceSetId={
                            activityInstanceIdsBySet
                        }
                        activityInstances={activityInstances}
                        onFinalizeInvalidActivityInstances={
                            handleInvalidActivityInstanceIds
                        }
                    />
                )
            }
            commentList={
                disableComments ? null : (
                    <CommentsByStepInstanceId
                        visible={true}
                        stepInstance={stepInstance}
                    />
                )
            }
            stepRef={sectionRefs && sectionRefs[id]}
            disableComments={disableComments}
        />
    );
};

export default StepInstanceCardById;
