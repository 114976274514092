import "./NoStepsPlaceholder.scss";

import { useTranslation } from "react-i18next";
import { FiPlusCircle } from "react-icons/fi";

import k from "i18n/keys";

import EmptyImage from "../assets/workflow-empty.svg";
import Button from "common/components/buttons/Button";

interface IProps {
    onAddStep: () => void;
    disabled?: boolean;
}

const NoStepsPlaceholder = (props: React.PropsWithChildren<IProps>) => {
    const { onAddStep, disabled } = props;
    const { t } = useTranslation();
    return (
        <div className="no-steps-placeholder--wrapper">
            <div className="no-steps-placeholder">
                <EmptyImage />

                <p>
                    {t(k.NO_STEPS)}
                    <br />

                    {t(k.WHY_NOT_ADD_SOME)}

                    <Button
                        disabled={disabled}
                        onClick={onAddStep}
                        size="small"
                        icon={<FiPlusCircle size="22" />}
                    >
                        {t(k.CREATE_NEW_STEP)}
                    </Button>
                </p>
            </div>
        </div>
    );
};

export default NoStepsPlaceholder;
