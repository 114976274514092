import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDropdownMenu } from "react-overlays";

import k from "i18n/keys";

import { IValueLabelItem } from "common/IValueLabelItem";
import Button from "common/components/buttons/Button";
import { SearchIcon } from "common/components/icons";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import Checkbox from "common/components/input-components/checkbox/Checkbox";
import Textfield from "common/components/input-components/textfield/Textfield";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import ITableSet from "http/ITableSet";

interface IProps {
    isReset: boolean;
    isLoading?: boolean;

    hideAll?: boolean;
    hideSearch?: boolean;

    testId?: string;

    options?: IValueLabelItem<string, string>[];
    initialSelectedIds?: string[];

    menuRef?: React.RefObject<HTMLDivElement>;

    onFilter: (ids: string[]) => void;
}

const getDefaultTableSet = (selectedIds?: string[]) => {
    if (selectedIds !== undefined && selectedIds.length > 0) {
        return {
            ids: selectedIds,
            values: selectedIds.reduce<Record<string, boolean>>((acc, cur) => {
                acc[cur] = true;

                return acc;
            }, {}),
        };
    }

    return { ids: [], values: {} };
};

export const FilterCheckboxMenu: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const {
        isReset,
        isLoading,
        hideAll,
        hideSearch,
        initialSelectedIds,
        testId,
        menuRef,
    } = props;

    const { t } = useTranslation();

    const latestSavedIds = React.useRef<string[]>([]);

    const [menuProps, { toggle, show }] = useDropdownMenu({
        flip: true,
        offset: [0, 8],
    });

    const [searchKeyword, setSearchKeyword] = useState("");

    const [filteredOptions, setFilteredOptions] =
        useState<IValueLabelItem<string, string>[]>();

    const options = filteredOptions ?? props.options ?? [];

    const [checkedValues, setCheckedValues] = useState<ITableSet<boolean>>(
        getDefaultTableSet(initialSelectedIds),
    );

    useEffect(() => {
        setCheckedValues(getDefaultTableSet(initialSelectedIds));
    }, [initialSelectedIds]);

    React.useEffect(() => {
        if (isReset) {
            handleReset();

            if (
                initialSelectedIds !== undefined &&
                initialSelectedIds.length > 0
            ) {
                latestSavedIds.current = initialSelectedIds;
            }

            setCheckedValues(
                latestSavedIds.current.reduce<ITableSet<boolean>>((acc, id) => {
                    acc.ids = acc.ids.concat(id);
                    acc.values[id] = true;

                    return acc;
                }, getDefaultTableSet()),
            );
        }
    }, [isReset]);

    const noOptions = options.length === 0;

    const handleFilter = (e: any) => {
        props.onFilter(checkedValues.ids);

        latestSavedIds.current = checkedValues.ids;

        toggle?.(false, e);
    };

    const handleChangeAllCheckedCheckbox = () => {
        setCheckedValues((prev) =>
            prev.ids.length < options.length
                ? options.reduce<ITableSet<boolean>>((acc, value) => {
                      acc.ids = acc.ids.concat(value.value);

                      acc.values[value.value] = true;

                      return acc;
                  }, getDefaultTableSet())
                : getDefaultTableSet(),
        );
    };

    const handleChangeCheckbox = (e: IInputChangeEvent<boolean>) => {
        const { name, value } = e;

        if (name) {
            if (value) {
                setCheckedValues((prev) => ({
                    ...prev,
                    ids: prev.ids.concat(name),
                    values: {
                        ...prev.values,
                        [name]: true,
                    },
                }));
            } else {
                setCheckedValues((prev) => ({
                    ...prev,
                    ids: prev.ids.filter((id) => id !== name),
                    values: {
                        ...prev.values,
                        [name]: false,
                    },
                }));
            }
        }
    };

    const handleReset = () => {
        setSearchKeyword("");

        setFilteredOptions(undefined);
    };

    const handleChangeSearchKeyword = (value: string) => {
        const search = value.trim();

        if (search) {
            setSearchKeyword(value);

            setFilteredOptions(
                props.options?.filter((x) =>
                    x.label.toLowerCase().includes(search.toLowerCase()),
                ) ?? [],
            );
        } else {
            handleReset();
        }
    };

    return show ? (
        <div
            {...menuProps}
            className="app-table--cell-filter__menu show"
            data-testid={testId}
        >
            <AnimatedSpinner isVisible={isLoading} position="center" />

            {hideSearch ? undefined : (
                <Textfield
                    value={searchKeyword}
                    placeholder={t(k.SEARCH)}
                    size="large"
                    postInputContent={<SearchIcon />}
                    onChange={handleChangeSearchKeyword}
                    helperText={
                        filteredOptions !== undefined &&
                        options.length === 0 && (
                            <Textfield.Error>{t(k.NO_MATCHES)}</Textfield.Error>
                        )
                    }
                />
            )}

            {hideAll
                ? undefined
                : filteredOptions === undefined && (
                      <Checkbox
                          label={t(k.ALL)}
                          value={checkedValues.ids.length > 0}
                          partial={
                              checkedValues.ids.length > 0 &&
                              checkedValues.ids.length < options.length
                          }
                          onChange={handleChangeAllCheckedCheckbox}
                      />
                  )}

            <div className="filter-options">
                {options.map((x) => (
                    <Checkbox
                        key={x.value}
                        label={x.label}
                        name={x.value}
                        value={checkedValues.values[x.value] ?? false}
                        onChange={handleChangeCheckbox}
                    />
                ))}
            </div>

            <Button
                variant="blue"
                transparent
                disabled={noOptions}
                testId={testId ? `${testId}-ApplyBtn` : undefined}
                onClick={handleFilter}
            >
                {checkedValues.ids.length > 0 ? (
                    <React.Fragment>
                        {t(k.APPLY)} ({checkedValues.ids.length})
                    </React.Fragment>
                ) : (
                    t(k.APPLY)
                )}
            </Button>

            {filteredOptions !== undefined && (
                <Button variant="danger" onClick={handleReset}>
                    {t(k.RESET)}
                </Button>
            )}
        </div>
    ) : null;
};
