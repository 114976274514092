import "./FilterToggle.scss";

import React, { PropsWithRef, useCallback, useContext, useEffect } from "react";

import DropdownContext from "react-overlays/esm/DropdownContext";

import FilterSvg from "assets/filter.svg";

interface IFilterToggleProps {
    testId?: string;
    isActive: boolean;
}

export const FilterToggle = (props: IFilterToggleProps) => {
    const { testId, isActive } = props;

    const dropdownContext = useContext(DropdownContext);

    const show = dropdownContext?.show;
    const toggle = dropdownContext?.toggle;
    const setToggle = dropdownContext?.setToggle;

    const menuElement = dropdownContext?.menuElement;
    const toggleElement = dropdownContext?.toggleElement;

    useEffect(() => {
        const handleDocumentClick = (e: MouseEvent) => {
            if (
                !e.target ||
                !(
                    menuElement?.contains(e.target as Node) ||
                    toggleElement?.contains(e.target as Node)
                )
            ) {
                toggle?.(false);
            }
        };

        if (show && menuElement) {
            document.addEventListener("click", handleDocumentClick, true);
        }

        return () => {
            document.removeEventListener("click", handleDocumentClick, true);
        };
    }, [show, menuElement, toggle]);

    const handleClick = useCallback(
        function (e: any) {
            toggle?.(!show, e);
        },
        [show, toggle],
    );
    return (
        <div
            ref={setToggle}
            aria-haspopup
            aria-expanded={!!show}
            className="filter-toggle"
            data-testid={testId ? `${testId}Toggle` : undefined}
            onClick={handleClick}
        >
            <FilterSvg className={isActive ? "active" : undefined} />
        </div>
    );
};
