import "./DropdownTreeSelect.scss";

import React, { useEffect, useState } from "react";

import Select, {
    GroupBase,
    InputActionMeta,
    InputProps,
    MultiValueProps,
    OptionProps,
    Props as SelectProps,
    SingleValueProps,
    components,
} from "react-select";

import { ArrowDownIcon, ArrowRightIcon } from "../icons";
import ClearIndicator from "../select-dropdown/components/ClearIndicator";
import { SelectCustomInput } from "../select-dropdown/components/CustomInput";
import DropdownIndicator from "../select-dropdown/components/DropdownIndicator";
import { IValueLabelItem } from "common/IValueLabelItem";
import { ITableSetWithOptions } from "http/ITableSetWithOptions";

export interface ITreeListOption<V extends string = string, L = string, P = any>
    extends IValueLabelItem<V, L, P> {
    parentId?: V | null;
    childIds?: V[];
    __expanded?: boolean;
    __checked?: boolean;
    __depth?: number;
    __path?: V[];
    __isPartiallySelected?: boolean;
    __displayInOptionList?: boolean;
}

interface DropdownTreeSelectProps<
    V extends string = string,
    L = string,
    P = any,
> {
    data: ITableSetWithOptions<ITreeListOption<V, L, P>, V, V>;
    selectedOptionValues?: V[] | null;
    isClearable?: boolean;
    disabled?: boolean;
    components?: Partial<
        SelectProps<ITreeListOption<V, L, P>, false>["components"]
    >;
    inputDataTestId?: string;
    showPartiallySelected?: boolean;
    onChange?: (option: ITreeListOption<V, L, P>[]) => void;
    customOptionLabel?: (option: ITreeListOption<V, L, P>) => JSX.Element;
}

function initializeOptionsState<
    V extends string = string,
    L extends string = string,
    P = any,
>(
    data: ITableSetWithOptions<ITreeListOption<V, L, P>, V, V>,
    keywordSearch: string,
    selectedOptionValues?: V[] | null,
    expandedNodes: Record<V, boolean> = {} as Record<V, boolean>,
): ITableSetWithOptions<ITreeListOption<V, L, P>, V, V> {
    const values = { ...data.values };

    const traverse = (
        nodeId: V,
        currentDepth: number = 0,
        currentPath: V[] = [],
        parentExpanded = true,
        displayInOptionList = true,
    ): { matchesSearch: boolean; hasCheckedDescendant: boolean } => {
        const node = values[nodeId];
        if (!node) return { matchesSearch: false, hasCheckedDescendant: false };

        const isExpanded = expandedNodes[nodeId] ?? false;

        node.__depth = currentDepth;
        node.__path = currentPath;
        node.__displayInOptionList = displayInOptionList;
        node.__expanded = isExpanded;

        let matchesSearch = false;
        if (keywordSearch !== "") {
            const label = node.label.toString().toLowerCase();
            const search = keywordSearch.toLowerCase();
            matchesSearch = label.includes(search);
        }

        let childMatches = false;
        let hasCheckedChild = false;

        const hasChildren = node.childIds && node.childIds.length > 0;

        if (hasChildren) {
            for (const childId of node.childIds ?? []) {
                const {
                    matchesSearch: childMatchesSearch,
                    hasCheckedDescendant: childHasChecked,
                } = traverse(
                    childId,
                    currentDepth + 1,
                    [...currentPath, nodeId],
                    isExpanded && parentExpanded,
                    isExpanded && displayInOptionList,
                );

                if (childMatchesSearch) {
                    childMatches = true;
                }

                if (childHasChecked) {
                    hasCheckedChild = true;
                }
            }
        }

        node.__checked = selectedOptionValues?.includes(node.value);
        node.__isPartiallySelected = hasCheckedChild;

        const hasCheckedDescendant = node.__checked || hasCheckedChild;

        const shouldInclude = matchesSearch || childMatches;

        if (keywordSearch) {
            node.__displayInOptionList = shouldInclude;
            if (hasChildren) {
                node.__expanded = shouldInclude;
            }
        }

        return { matchesSearch: shouldInclude, hasCheckedDescendant };
    };

    data.options.forEach((topId) => {
        traverse(topId);
    });

    return {
        ...data,
        values,
    };
}

function flattenOptions<
    V extends string = string,
    L extends string = string,
    P = any,
>(
    data: ITableSetWithOptions<ITreeListOption<V, L, P>, V, V>,
    expandedNodes: Record<V, boolean>,
): ITreeListOption<V, L, P>[] {
    const flattened: ITreeListOption<V, L, P>[] = [];

    const traverse = (nodeId: V) => {
        const node = data.values[nodeId];
        if (!node || !node.__displayInOptionList) return;

        flattened.push(node);

        if (node.childIds) {
            node.childIds.forEach((childId) => traverse(childId));
        }
    };

    data.options.forEach((topId) => {
        traverse(topId);
    });

    return flattened;
}

const DropdownTreeSelect = <
    V extends string = string,
    L extends string = string,
    P = any,
>({
    data,
    onChange,
    customOptionLabel,
    disabled,
    isClearable,
    selectedOptionValues,
    components: customComponents,
    inputDataTestId,
    showPartiallySelected = false,
}: DropdownTreeSelectProps<V, L, P>) => {
    const [optionsState, setOptionsState] = useState(() =>
        initializeOptionsState(data, "", selectedOptionValues),
    );
    const [expandedNodes, setExpandedNodes] = useState<Record<V, boolean>>(
        {} as Record<V, boolean>,
    );
    const [selectedOptions, setSelectedOptions] = useState<V[]>(
        selectedOptionValues ?? [],
    );

    const [keywordSearch, setKeywordSearch] = useState("");

    useEffect(() => {
        setOptionsState(
            initializeOptionsState(
                data,
                keywordSearch,
                selectedOptions,
                expandedNodes,
            ),
        );
    }, [data, keywordSearch, selectedOptions, expandedNodes]);

    useEffect(() => {
        setSelectedOptions(selectedOptionValues ?? []);
    }, [selectedOptionValues]);

    const updatePartialSelectionStates = (
        values: Record<V, ITreeListOption<V, L, P>>,
        topLevelIds: V[],
    ) => {
        const traverse = (nodeId: V): boolean => {
            const node = values[nodeId];
            if (!node) return false;

            const isChecked = node.__checked || false;
            let hasCheckedDescendant = false;

            if (node.childIds && node.childIds.length > 0) {
                for (const childId of node.childIds) {
                    const childHasCheckedDescendant = traverse(childId);
                    if (childHasCheckedDescendant) {
                        hasCheckedDescendant = true;
                    }
                }
            }

            if (!isChecked && hasCheckedDescendant) {
                node.__isPartiallySelected = true;
            } else {
                node.__isPartiallySelected = false;
            }

            return isChecked || hasCheckedDescendant;
        };

        for (const topId of topLevelIds) {
            traverse(topId);
        }
    };

    const handleOptionChange = (option: ITreeListOption<V, L, P> | null) => {
        if (!option) {
            handleClearSelection();
            return;
        }

        const updatedValues = { ...optionsState.values };

        Object.keys(updatedValues).forEach((key) => {
            const value = updatedValues[key];
            if (value) {
                value.__checked = false;
                value.__isPartiallySelected = false;
            }
        });

        const currentOption = updatedValues[option.value];
        if (!currentOption) return;

        currentOption.__checked = true;

        updatePartialSelectionStates(
            updatedValues as Record<V, ITreeListOption<V, L, P>>,
            optionsState.options,
        );

        const updatedOptions: ITableSetWithOptions<
            ITreeListOption<V, L, P>,
            V,
            V
        > = {
            ...optionsState,
            values: updatedValues,
        };

        setOptionsState(updatedOptions);
        setSelectedOptions([currentOption.value]);

        onChange?.([currentOption]);
    };

    const toggleNode = (value: V) => {
        setExpandedNodes((prev) => ({
            ...prev,
            [value]: !prev[value],
        }));
    };

    const handleClearSelection = () => {
        const updatedValues = { ...optionsState.values };

        Object.keys(updatedValues).forEach((key) => {
            const value = updatedValues[key];
            if (value) {
                value.__checked = false;
                value.__isPartiallySelected = false;
            }
        });

        const updatedOptions: ITableSetWithOptions<
            ITreeListOption<V, L, P>,
            V,
            V
        > = {
            ...optionsState,
            values: updatedValues,
        };

        setOptionsState(updatedOptions);
        setSelectedOptions([]);

        onChange?.([]);
    };

    const flattenedOptions = flattenOptions(optionsState, expandedNodes);

    const OptionComponent: React.FC<
        OptionProps<ITreeListOption<V, L, P>, false>
    > = (props) => {
        const { data, innerRef, innerProps } = props;
        const isParent = data.childIds && data.childIds.length > 0;
        const isExpanded = expandedNodes[data.value] || false;
        const indentation = (data.__depth || 0) * 20;

        return (
            <div
                ref={innerRef}
                {...innerProps}
                className="dropdown-tree-select__option"
                style={{ paddingLeft: indentation + 10 }}
            >
                <div
                    className="accordion"
                    onClick={
                        isParent
                            ? (e) => {
                                  e.stopPropagation();
                                  toggleNode(data.value);
                              }
                            : undefined
                    }
                >
                    {isParent && (
                        <>
                            {isExpanded ? (
                                <ArrowDownIcon />
                            ) : (
                                <ArrowRightIcon />
                            )}
                        </>
                    )}
                </div>

                <label className="custom-radio" title={data.label}>
                    <input
                        type="radio"
                        name="tree-radio-group"
                        checked={data.__checked ?? false}
                        onChange={() => {
                            handleOptionChange(data);
                        }}
                        disabled={disabled}
                    />
                    <span
                        className={`radio-indicator ${
                            data.__checked ? "checked" : ""
                        } ${
                            data.__isPartiallySelected && showPartiallySelected
                                ? "partially-selected"
                                : ""
                        }`}
                    ></span>
                    {customOptionLabel ? customOptionLabel(data) : data.label}
                </label>
            </div>
        );
    };

    const SingleValueComponent = (
        props: SingleValueProps<ITreeListOption<V, L, P>, false>,
    ) => {
        const { data } = props;
        return (
            <components.SingleValue {...props} key={data.value}>
                <span style={{ fontWeight: "bold" }}>{data.label}</span>
            </components.SingleValue>
        );
    };

    const MultiValueComponent = (
        props: MultiValueProps<ITreeListOption<V, L, P>, false>,
    ) => {
        return <components.MultiValue {...props} />;
    };

    return (
        <Select<
            ITreeListOption<V, L, P>,
            false,
            GroupBase<ITreeListOption<V, L, P>>
        >
            className="dropdown-tree-select"
            classNamePrefix="dropdown-tree-select"
            value={
                selectedOptionValues?.[0] !== undefined
                    ? optionsState.values[selectedOptionValues?.[0]]
                    : undefined
            }
            inputId={inputDataTestId}
            inputDataTestId={inputDataTestId}
            options={flattenedOptions}
            onChange={handleOptionChange}
            components={{
                Option: OptionComponent,
                SingleValue: SingleValueComponent,
                MultiValue: MultiValueComponent,
                ClearIndicator: ClearIndicator,
                DropdownIndicator: DropdownIndicator,
                Input: SelectCustomInput,
                ...customComponents,
            }}
            isDisabled={disabled}
            inputValue={keywordSearch}
            isClearable={isClearable}
            onInputChange={(inputValue, { action }: InputActionMeta) => {
                if (action === "input-change") {
                    setKeywordSearch(inputValue);
                } else if (action === "menu-close") {
                    setKeywordSearch("");
                }
            }}
            unstyled
            filterOption={() => true}
            closeMenuOnSelect
        />
    );
};

export default DropdownTreeSelect;
