import React, { useEffect, useRef, useState } from "react";

import { createPortal } from "react-dom";

interface IProps {
    targetId?: string;
    targetRef?: React.RefObject<HTMLElement>;
    targetElement?: HTMLElement;
    retryDelay?: number;
    onPortalReady?: (newReady: boolean) => void;
}

const PortalOpener: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { targetId, targetRef, retryDelay = 200, children } = props;

    const targetElementRef = useRef<HTMLElement | null>(null);

    const [isPortalReady, setIsPortalReady] = useState<boolean>(false);

    useEffect(() => {
        const tryFindTargetElement = () => {
            const targetElement = targetId
                ? document.getElementById(targetId)
                : (targetRef?.current ?? props.targetElement);
            if (targetElement) {
                targetElementRef.current = targetElement;
                setIsPortalReady(true);
            } else {
                const timeoutId = setTimeout(tryFindTargetElement, retryDelay);
                return () => clearTimeout(timeoutId);
            }
        };

        tryFindTargetElement();
    }, [targetId, targetRef?.current, props.targetElement, retryDelay]);

    useEffect(() => {
        props.onPortalReady?.(isPortalReady);
    }, [isPortalReady, props]);

    if (isPortalReady && targetElementRef.current) {
        return createPortal(children, targetElementRef.current);
    }

    return null;
};

export default PortalOpener;
