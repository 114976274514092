import "./TitleWithValidation.scss";

import { useMemo } from "react";

import classNames from "classnames";

import ValidationLabel from "./ValidationLabel";

interface IProps {
    id?: string;
    htmlFor?: string;
    title: React.ReactNode;
    required?: boolean;
    className?: string;
    bold?: boolean;
    size?: "title-sm";
    errors?: React.ReactNode;
    testId?: string;
    validationTestId?: string;
}

const TitleWithValidation: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { title, errors, required, testId, validationTestId, htmlFor, id } =
        props;
    const className = classNames("title-with-validation", props.className);

    const labelClassName = classNames(
        "title-with-validation--label",
        props.size,
        {
            "title-bold": props.bold,
        },
    );

    return (
        <div className={className} data-testid={testId}>
            <label htmlFor={htmlFor} className={labelClassName}>
                {title}
            </label>

            {required && (
                <div className="title-with-validation--required">*</div>
            )}
            {errors && (
                <div className="title-with-validation--validation">
                    <ValidationLabel
                        id={id}
                        darkColor
                        errors={errors}
                        testId={validationTestId}
                    />
                </div>
            )}
        </div>
    );
};

export default TitleWithValidation;
